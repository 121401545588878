import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import CheckIcon from '@material-ui/icons/Check';
import ArrowBack from '@material-ui/icons/ArrowBack';

import {
  AimIconAndTextButton,
  AimTypography,
  CustomIntl,
  ParticipationForm,
  defaultSaveData,
  theme,
} from '@aim/components';
import { appState, constants } from '@aim/common';
import translation from './translation';
import { getEventDetails } from './gqlHelper';
import GuestsForm from './GuestsForm';

const toSkipBaseFields = [
  'title',
  'typology',
  'feeDateRange',
  'givenName',
  'familyName',
  'email',
  'type',
  'phone',
  'status',
];

const ProfileForm = () => {
  const { eventId } = useParams();
  const intl = CustomIntl(useIntl());
  const i18n = translation.participationEdit(intl);
  //il cluster in questo caso sarà sempre pax
  const [cluster] = useState(constants.Clusters.Pax.id);
  const [user, setUser] = useState();
  const [profiles, setProfiles] = useState([]);
  const [profile, setProfile] = useState();
  const [feeDateRanges] = useState([]);
  const [typologies] = useState([]);
  const [currentParticipation, setCurrentParticipation] = useState();
  const saveDataRef = useRef();
  const saveGuestsDataRef = useRef();
  const participationDataRef = useRef();

  const history = useHistory();
  useEffect(() => {
    const userSubscription = appState.user.subscribe((nextUser) => {
      setUser(nextUser?.userAndParticipation?.participation);
    });
    return () => userSubscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (!user) return;

    const getData = async () => {
      showLoader();

      const evDetails = await getEventDetails(eventId, cluster, false);

      const nextProfiles = evDetails.profiles?.items
        .map((p) => ({
          label: p.name + (p?.category?.name ? ` - ${p.category.name}` : ''),
          value: p.id,
          maxAccompanyingPersons: p.maxAccompanyingPersons,
        }))
        .sort((a, b) => a.label?.localeCompare(b.label));
      setProfiles(nextProfiles);

      let p = {
        cluster,
        event: { id: eventId },
        id: user.id,
        cognitoUserId: user.cognitoUserId,
        email: user.email,
        profile: user.profile,
        fieldValues: user.fieldValues,
        guests: user.guests,
      };
      setCurrentParticipation(p);
      hideLoader();
    };

    getData();
  }, [user]);

  const showLoader = () => appState.isLoader.next(true);
  const hideLoader = () => appState.isLoader.next(false);

  const sendParticipationData = (submittedData, dirtyFields) => {
    if (!saveGuestsDataRef.current) sendData({ submittedData, dirtyFields });
    participationDataRef.current = { submittedData, dirtyFields };
    saveGuestsDataRef.current.click();
  };

  const sendGuestsData = (data) => {
    const nextdata = { ...data, ...participationDataRef.current };
    sendData(nextdata);
  };

  const sendData = async ({ submittedData, dirtyFields, guests }) => {
    showLoader();
    const obj = {
      participation: currentParticipation,
      submittedData,
      dirtyFields,
      guests: guests?.map((guest) => ({
        ...guest,
        country: guest.country?.label,
        countryObj: guest.country,
      })),
    };
    await defaultSaveData({
      participation: currentParticipation,
      submittedData,
      dirtyFields,
      editFrontOffice: true,
    });

    await appState.purchaseData.next(obj);
    hideLoader();
    history.push(`/events/${eventId}/tickets/fee`);
  };

  const handleSave = () => {
    if (!saveDataRef.current) return;
    saveDataRef.current.click();
  };

  return (
    <div
      style={{ flex: 1, minWidth: 'calc(100vw - 480px)', margin: '0px 180px' }}
    >
      <AimIconAndTextButton
        isUpperCase
        variant="none"
        text={i18n.actions.backButton}
        style={{
          padding: 0,
        }}
        onClick={() => history.replace(`/events/${eventId}/landing`)}
      >
        <ArrowBack />
      </AimIconAndTextButton>
      <AimTypography variant="h1">{i18n.page.welcome}</AimTypography>
      <AimTypography variant="text">{i18n.page.subtitle}</AimTypography>
      {user && profiles && currentParticipation && (
        <div
          style={{
            height: '50vh',
            overflowY: 'auto',
            overflowX: 'hidden',
            paddingRight: '20px',
          }}
        >
          <div
            style={{ background: theme.colors.greyScale.grey2, padding: 10 }}
          >
            <ParticipationForm
              {...{
                intl,
                participation: currentParticipation,
                profiles,
                typologies,
                feeDateRanges,
                refButton: saveDataRef,
                onSaveDataFn: sendParticipationData,
                showAdminFields: false,
                showHiddenFields: false,
                toSkipBaseFieldsIds: toSkipBaseFields,
                // isReadOnly: true,
                excludePaxAnagraphicFields: true,
                variant: 'white',
                requiredBaseFields: ['profile'],
                onSetProfile: setProfile,
              }}
            >
              {/* {other form} */}
            </ParticipationForm>
            <GuestsForm
              {...{
                intl,
                i18n,
                participation: currentParticipation,
                variant: 'white',
                profile,
                refButton: saveGuestsDataRef,
                onSendDataFn: sendGuestsData,
                defaultGuests: appState.purchaseData.getValue()?.guests,
              }}
            />

            <div
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'flex-end',
                marginTop: 20,
              }}
            >
              <AimIconAndTextButton
                variant="yellowFill"
                text={i18n.actions.send}
                onClick={handleSave}
              >
                <CheckIcon />
              </AimIconAndTextButton>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileForm;
