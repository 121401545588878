import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { parseISO } from 'date-fns';

import Grid from '@material-ui/core/Grid';

import { CountdownCircleTimer } from 'react-countdown-circle-timer';

import { aws, appState, fileHelper, constants } from '@aim/common';
import { CustomIntl, AimTypography, useAimMediaQuery } from '@aim/components';
import {
  getEventGrants,
  listProductsRegistration,
} from './shared/landingGqlHelper';
import { translation } from './landing/translation';

import {
  getEventData,
  EventHeader,
  DefaultLanding,
  SponsorLanding,
  AgencyLanding,
} from './landing/index.js';

import { utcToZonedTime } from 'date-fns-tz';

const MainContainer = ({ children }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      alignItems: 'center',
    }}
  >
    {children}
  </div>
);

const disabledCards = {};

const getGrantsForTheEvent = async (eventId) => {
  const res = await getEventGrants(eventId);
  if (res?.data?.getEvent?.grant?.items)
    return res?.data?.getEvent?.grant?.items;
  return false;
};

import image from './../../assets/images/landing-image.png';

const parseTime = (remainingTime) => {
  let seconds = parseInt(remainingTime, 10);

  let days = Math.floor(seconds / (3600 * 24));
  seconds -= days * 3600 * 24;
  let hours = Math.floor(seconds / 3600);
  seconds -= hours * 3600;
  let minutes = Math.floor(seconds / 60);
  seconds -= minutes * 60;

  return (
    <Grid container>
      {days > 0 && (
        <Grid item xs={12}>
          {days} days
        </Grid>
      )}
      <Grid item xs={12}>
        {hours} hours
      </Grid>
      <Grid item xs={12}>
        {minutes} minutes
      </Grid>
      <Grid item xs={12}>
        {seconds} seconds
      </Grid>
    </Grid>
  );
};

const sponsorCheck = () => {
  return (
    (!appState.user.getValue()?.userAndParticipation?.participation?.sponsor
      ?.isDeleted &&
      appState.user.getValue()?.userAndParticipation?.participation?.sponsor) ||
    (!appState.user.getValue()?.userAndParticipation?.participation?.sponsor
      ?.isDeleted &&
      appState.user.getValue()?.userAndParticipation?.participation
        ?.sponsorStaff?.sponsor)
  );
};

const Landing = () => {
  const { eventId } = useParams();
  const intl = CustomIntl(useIntl());
  const [userData] = useState({
    sponsor: sponsorCheck(),
    agency: appState.user.getValue()?.userAndParticipation?.participation
      ?.agency,
    participation: appState.user.getValue()?.userAndParticipation
      ?.participation,
    hasTicket: appState.user.getValue()?.userAndParticipation?.participation
      ?.hasTicket,
  });
  const [data, setData] = useState();
  const [dataType, setDataType] = useState('default');
  const [configuration] = useState(appState.eventConfiguration.getValue());
  const [eventInfo] = useState(appState.eventInfo.getValue());

  const [coverImage, setCoverImage] = useState(null);

  const { getAwsS3Link } = fileHelper;

  const [
    secondsRemainingBeforeActivation,
    setSecondsRemainingBeforeActivation,
  ] = useState(0);

  const [grants, setGrants] = useState([]);
  const [grantsParticipationsArray, setGrantsParticipationsArray] = useState(
    []
  );

  const { isMobile } = useAimMediaQuery();

  useEffect(() => {
    const getImages = async () => {
      if (configuration?.welcomePageCoverImage) {
        const link = getAwsS3Link(
          `events/${eventId}/configuration/welcomePageCoverImage`,
          configuration.welcomePageCoverImage
        );
        const convertedLink = aws.s3.getS3ObjectUrl(link);

        setCoverImage(convertedLink);
      }
    };

    const loadGrants = () => {
      if (
        configuration?.welcomePageAbstractGrant ||
        configuration?.welcomePageEducationalGrant
      ) {
        getGrantsForTheEvent(eventId).then((result) => {
          if (result) {
            const today = Math.floor(new Date().getTime() / 1000);

            result = result.reduce(function (res, y) {
              if (!y.closingDate) {
                // res.push(y);
              } else {
                try {
                  const dateInSeconds = Math.floor(
                    new Date(y.closingDate).getTime() / 1000
                  );
                  if (dateInSeconds >= today) res.push(y);
                } catch (e) {
                  res.push(y);
                }
              }

              return res;
            }, []);

            setGrants(result);
          }
        });
      }
    };

    const fetchProduct = async () => {
      if (!userData) return;
      try {
        if (userData.hasTicket) {
          // disabledCards.buyTickets = true;
        } else {
          const purchasedTicketsCount = await listProductsRegistration(eventId);
          disabledCards.buyTickets = eventInfo.maxParticipants
            ? purchasedTicketsCount >= eventInfo.maxParticipants
            : false;
        }
      } catch (err) {
        console.error(err);
      }
    };

    const loadData = async () => {
      if (userData?.participation?.isDeleted) return;
      const event = await getEventData(
        eventId,
        userData?.participation?.isSpeaker
          ? constants.AgendaTypes.SPEAKERS.queryField
          : constants.AgendaTypes.AGENDA.queryField
      );
      const {
        agenda,
        start,
        end,
        name,
        configuration,
        sponsorPriceRanges,
      } = event;
      const isPresenter = event?.abstractService?.abstractDocuments?.items?.some(
        (ab) => ab.presenter?.participant?.id === userData.participation?.id
      );
      let nextData = {
        agenda: agenda,
        countdown: configuration?.countdown,
        activationDate: configuration?.eventActivationDate,
        deactivationDate: configuration?.eventDeactivationDate,
        name: name,
        start: parseISO(start),
        end: parseISO(end),
        abstractServiceId: event.abstractService?.id,
        isPresenter,
      };
      if (userData?.sponsor) {
        setDataType('sponsor');

        const s3FolderCover = `events/${eventId}/sponsor/welcome-page/cover/`;
        const s3FolderGallery = `events/${eventId}/sponsor/welcome-page/gallery/`;
        const s3FolderProspectus = `events/${eventId}/sponsor/welcome-page/prospectus/`;

        const { title, subtitle, content, cover, gallery, document } =
          event?.sponsor || {};

        nextData = {
          ...nextData,
          id: userData?.sponsor.id,
          title,
          subtitle,
          content: content && JSON.parse(content),
          sponsorService: {
            fields: event.services?.items?.[0]?.customFields?.items,
          },
          sponsorPriceRanges,
          cover:
            cover &&
            (await aws.Storage.get(
              `${s3FolderCover}${cover.id}${cover.extension}`
            )),
          gallery: gallery?.items?.length
            ? await Promise.all(
                gallery.items.map(
                  async (image) =>
                    await aws.Storage.get(
                      `${s3FolderGallery}${image.id}${image.extension}`
                    )
                )
              )
            : [],
          document:
            document &&
            (await aws.Storage.get(
              `${s3FolderProspectus}${document.id}${document.extension}`
            )),
        };
      } else if (userData?.agency) {
        setDataType('agency');
        try {
          const s3FolderCover = `events/${eventId}/agency/welcome-page/cover/`;
          const s3FolderPolicies = `events/${eventId}/agency/welcome-page/policies/`;

          const { cover, policies } = event.agency;
          nextData = {
            ...nextData,
            id: userData?.agency.id,
            instructions: event.agency?.instructions
              ? event.agency.instructions
              : null,
            start: parseISO(start),
            end: parseISO(end),
            cover:
              cover &&
              (await aws.Storage.get(
                `${s3FolderCover}${cover.id}${cover.extension}`
              )),
            policies: policies?.items?.length
              ? await Promise.all(
                  policies.items.map(async (policy) => ({
                    ...policy,
                    url: await aws.Storage.get(
                      `${s3FolderPolicies}${policy.id}${policy.extension}`
                    ),
                  }))
                )
              : [],
          };
        } catch (error) {
          console.error(error);
          nextData = {
            ...nextData,
            id: userData?.agency.id,
            start: parseISO(start),
            end: parseISO(end),
          };
          setData(nextData);
        }
      }
      setData(nextData);
    };

    loadData();
    fetchProduct();
    getImages();
    loadGrants();
  }, [configuration]);

  const handleSendAbstractsClick = () => {
    const targetRoute =
      userData?.participation.isReviewer || data?.isPresenter
        ? `/events/${eventId}/abstracts-role-selection/${data?.abstractServiceId}`
        : `/events/${eventId}/abstracts`;
    return targetRoute;
  };

  useEffect(() => {
    if (!data || !eventInfo || !data.activationDate) {
      return;
    }

    const activationDate = parseISO(data.activationDate).toISOString();
    const nowDate = new Date().toISOString();

    const activationDateTimestamp = Math.floor(
      utcToZonedTime(
        new Date(activationDate),
        eventInfo?.timezone || 'Europe/Rome'
      ).getTime() / 1000
    );
    const nowDateTimestamp = Math.floor(new Date(nowDate).getTime() / 1000);

    const secondsRemaining = activationDateTimestamp - nowDateTimestamp;

    if (secondsRemaining < 0) {
      appState.areHeaderMenuVoicesShown.next(true);
    } else {
      appState.areHeaderMenuVoicesShown.next(false);
    }

    setSecondsRemainingBeforeActivation(secondsRemaining);
  }, [data, eventInfo]);

  if (!data || !eventInfo) {
    return null;
  }

  return (
    <MainContainer>
      {secondsRemainingBeforeActivation > 0 && configuration?.countdown ? (
        <div
          style={{
            backgroundSize: 'cover',
            backgroundImage: `linear-gradient(rgba(0,0,0,0.45), rgba(0,0,0,0.45)), url(${
              coverImage || image
            })`,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            // padding: isMobile ? 45 : 80,
            textAlign: 'center',
            height: '100%',
          }}
        >
          <CountdownCircleTimer
            isPlaying
            duration={secondsRemainingBeforeActivation}
            size={isMobile ? 300 : 500}
            strokeLinecap="square"
            // initialRemainingTime={initialRemainingTime}
            onComplete={() => {
              appState.areHeaderMenuVoicesShown.next(true);
              setTimeout(() => {
                location.reload();
              }, 5000);
            }}
            colors={[configuration?.primaryColor || '#FFF', '#FFF']}
            trailColor={configuration?.secondaryColor || '#FFF'}
          >
            {({ remainingTime }) => (
              <AimTypography
                variant="h1"
                textAlign="center"
                margin={2}
                boxStyle={{ color: '#FFFFFF' }}
                style={{ color: '#FFFFFF' }}
              >
                {parseTime(remainingTime)}
              </AimTypography>
            )}
          </CountdownCircleTimer>
        </div>
      ) : (
        <>
          <EventHeader
            {...{
              eventId,
              start: data.start,
              end: data.end,
              name: data.name,
              participant: userData?.participation,
              grants: grants,
              secondsRemainingBeforeActivation,
            }}
          />
          {dataType === 'sponsor' && configuration && (
            <SponsorLanding
              eventId={eventId}
              data={data}
              sponsorUser={userData?.sponsor}
            />
          )}
          {dataType === 'agency' && configuration && (
            <AgencyLanding eventId={eventId} data={data} intl={intl} />
          )}
          {dataType === 'default' &&
            configuration &&
            secondsRemainingBeforeActivation <= 0 && (
              <DefaultLanding
                intl={intl}
                translation={translation}
                eventId={eventId}
                configuration={configuration}
                grants={grants}
                grantsParticipations={grantsParticipationsArray}
                disabledCards={disabledCards}
                sendAbstractRoute={handleSendAbstractsClick()}
                eventInfo={eventInfo}
                isFaculty={userData.participation.isFaculty}
                agenda={data.agenda}
              />
            )}
        </>
      )}
    </MainContainer>
  );
};

export default Landing;
