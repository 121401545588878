import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import axios from 'axios';

import Grid from '@material-ui/core/Grid';

import GradeIcon from '@material-ui/icons/Grade';
import GetAppIcon from '@material-ui/icons/GetApp';
import ArrowBack from '@material-ui/icons/ArrowBack';
import CardTravelIcon from '@material-ui/icons/CardTravel';

import {
  CustomIntl,
  AimTypography,
  PrintPDFTicket,
  AimIconAndTextButton,
  LetterLayoutTemplate,
} from '@aim/components';

import { format, formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz';

import { constants, appState, aws, utilities, fileHelper } from '@aim/common';

import { translation } from './participationServicesPublicPage/translation';
import { GeneralInfoContent } from './participationServicesPublicPage/GeneralInfoContent';
import { getEventLetterLayoutTemplate } from './participationServicesPublicPage/gqlHelper';

const grey = '#F8F6FA';
const slightlyDarkerGrey = '#84819A47';

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const formatDate = (dateString) => {
  const currentTimezone = appState.eventInfo.getValue().timezone;
  const date = zonedTimeToUtc(dateString, currentTimezone);

  return formatInTimeZone(date, currentTimezone, 'dd/MM/yyyy');
};

const downloadAttachmentOrZipIfMoreThanOne = async ({
  attachments,
  zipName,
}) => {
  if (attachments.length === 1) {
    window.open(attachments[0]?.presignedUrl);
  } else {
    const files = await Promise.all(
      attachments.map(async (a) => {
        const { data } = await axios.get(a.presignedUrl);
        return { ...a, Body: data };
      })
    );
    console.log('files', files);
    await fileHelper.downloadZipFromS3(files, zipName);
  }
};

const { PaymentTypesWithTranslations, PaymentStatuses } = constants;

const getBasicData = ({ product, intl }) => {
  const { givenName, familyName, email, phone } = product?.productOwner || {};
  // product?.payment?.BillingInformation || {};

  const clientInformations = {
    givenName,
    familyName,
    email,
    phone,
  };

  const billingInformations =
    //skip payment details in free payments
    product?.payment?.BillingInformation && product?.payment?.amount > 0
      ? {
          ...(product?.payment?.BillingInformation || {}),
          isVATExent: product?.payment?.BillingInformation?.isVatEvent,
          invoiceTo: product?.payment?.BillingInformation?.invoiceTo
            ? Object.values(constants.InvoiceToType)
                .find(
                  (it) =>
                    it.id === product?.payment?.BillingInformation?.invoiceTo
                )
                ?.label(intl)
            : null,
          reference:
            product?.payment?.BillingInformation?.invoiceTo ===
            constants.InvoiceToType.COMPANY.id
              ? product?.payment?.BillingInformation.reference
              : givenName,
        }
      : null;

  const paymentDetails =
    //skip payment details in free payments
    product?.payment && product?.payment?.amount > 0
      ? {
          // ...(product?.payment || {}),
          total: utilities.formatNumber(
            utilities.decodeDbNumber(product?.payment?.amount)
          ),
          paymentMethod:
            Object.values(PaymentTypesWithTranslations)
              .find((x) => x.key === product?.payment?.paymentType)
              .label(intl) || product?.payment?.paymentType,
          paymentStatus: product?.payment?.paymentStatus
            ? Object.values(PaymentStatuses)
                .find((x) => x.key === product?.payment?.paymentStatus)
                .label(intl) || product?.payment?.paymentStatus
            : null,
          paymentReason: product?.gateway?.shop?.ibanCausal,
          IBAN: product?.gateway?.shop?.iban,
          addressedTo: product?.gateway?.shop?.addressedTo,
          paymentObject: product?.payment,
        }
      : null;

  return { clientInformations, billingInformations, paymentDetails };
};

const MainContainer = ({ children }) => (
  <div
    style={{
      // display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      // alignItems: 'center',
      paddingLeft: '5%',
      paddingTop: 20,
      paddingRight: '5%',
      paddingBottom: 20,
    }}
  >
    {children}
  </div>
);

const InfoAreaAllotment = ({ blockData, intl, i18n }) => {
  const data = blockData.data.length ? blockData.data : [];

  const allotmentInformations = blockData;

  return data.length > 0 ? (
    <Grid
      item
      xs={12}
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
      }}
    >
      <Grid container xs={12} style={{ margin: '10px 0px' }}>
        {data.map((hotel, ndx) => {
          const {
            clientInformations,
            billingInformations,
            paymentDetails,
          } = getBasicData({ product: hotel, intl });

          return (
            <>
              <Grid
                key={ndx}
                item
                container
                xs={12}
                style={{ padding: 20, marginBottom: 20, backgroundColor: grey }}
              >
                <Grid item xs={12}>
                  <AimTypography margin={0} variant={`h4`}>
                    {i18n.general.bookingInfo}
                  </AimTypography>
                </Grid>
                <Grid item xs={12}>
                  <Spacer pixels={5} />
                  <AllotmentBox key={ndx} {...{ data: hotel, i18n, intl }} />
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      paddingTop: 5,
                    }}
                  >
                    <PrintPDFTicket
                      {...{
                        module: 'allotment',
                        allotmentInformations: { allotmentInformations, ndx },
                        billingInformations,
                        clientInformations,
                        paymentDetails,
                        intl,
                        i18n,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          );
        })}
      </Grid>
    </Grid>
  ) : (
    <></>
  );
};

const InfoAreaRegistration = ({ blockData, intl, i18n }) => {
  const data = blockData.data;
  return data.length ? (
    <Grid
      item
      xs={12}
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
      }}
    >
      <Grid container xs={12} style={{ margin: '10px 0px' }}>
        {data.map((product, ndx) => {
          const {
            clientInformations,
            billingInformations,
            paymentDetails,
          } = getBasicData({ product, intl });

          const registrationInformations = {
            registration: product?.ticket?.feeBracket?.feeDateRange?.label,
            start: formatDate(product.ticket.feeBracket.feeDateRange.start),
            end: formatDate(product.ticket.feeBracket.feeDateRange.end),
            billingDocument: product.billingDocument,
            creditNotesDocument: product.creditNotesDocument,
            serviceId: product.serviceId,
          };

          return (
            <Grid
              key={ndx}
              item
              container
              xs={12}
              style={{ padding: 20, marginBottom: 20, backgroundColor: grey }}
            >
              <Grid item xs={12}>
                <AimTypography margin={0} variant={`h4`}>
                  {i18n.general.registrationInfo}
                </AimTypography>
              </Grid>
              <Grid item xs={12}>
                <Spacer pixels={5} />
                <Divider />
                <Spacer pixels={5} />
                <RegistrationBox key={ndx} {...{ data: product, i18n }} />
                <Grid
                  item
                  xs={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    paddingTop: 5,
                  }}
                >
                  <PrintPDFTicket
                    {...{
                      module: 'registration',
                      clientInformations,
                      billingInformations,
                      paymentDetails,
                      registrationInformations,
                      intl,
                      i18n,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  ) : (
    <></>
  );
};

const InfoDownloadArea = ({ blockData, i18n }) => {
  const data = blockData.data;
  return data.length ? (
    <Grid
      item
      xs={12}
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
      }}
    >
      <Grid container xs={12} style={{ margin: '10px 0px' }}>
        {data
          .filter(({ attachments }) => attachments.length)
          .map(({ attachments, name }) => {
            return (
              <Grid
                key={name}
                item
                container
                xs={12}
                style={{ padding: 20, marginBottom: 20, backgroundColor: grey }}
              >
                <Grid item xs={12}>
                  <AimTypography margin={0} variant={`h4`}>
                    {i18n.general?.[name]}
                  </AimTypography>
                </Grid>
                <Grid item xs={12}>
                  <Spacer pixels={5} />
                  <Divider />
                  <Spacer pixels={5} />
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      paddingTop: 5,
                    }}
                  >
                    <AimIconAndTextButton
                      variant="primary"
                      text={i18n.general.download}
                      onClick={() =>
                        downloadAttachmentOrZipIfMoreThanOne({
                          attachments,
                          zipName: `${name}.zip`,
                        })
                      }
                    >
                      <GetAppIcon />
                    </AimIconAndTextButton>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
      </Grid>
    </Grid>
  ) : (
    <></>
  );
};

const InfoAreaAdditionaServices = ({
  blockData,
  intl,
  // clientInformations,
  i18n,
}) => {
  const data = blockData.data;
  console.log('🚀 ~ .map ~ data:', data);
  return data.length ? (
    <Grid
      item
      xs={12}
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
      }}
    >
      <Grid container xs={12} style={{ margin: '10px 0px' }}>
        {data
          .sort((a, b) =>
            new Date(
              `${a.additionalService.dateService} ${a.additionalService.startTime}`
            ) >
            new Date(
              `${b.additionalService.dateService} ${b.additionalService.startTime}`
            )
              ? 1
              : -1
          )
          .map((product, ndx) => {
            const {
              clientInformations,
              billingInformations,
              paymentDetails,
            } = getBasicData({ product, intl });

            const additionalServicesInformations = {
              title: product?.additionalService?.title,
              description: product?.additionalService?.description,
              date: formatDate(product?.additionalService?.dateService),
              startTime: product?.additionalService?.startTime,
              endTime: product?.additionalService?.endTime,
              locationName: product?.additionalService?.locationName,
              locationAddress: product?.additionalService?.locationAddress,
              paymentType: paymentDetails?.paymentMethod.toLowerCase() || '',
              payment: paymentDetails,
              billingDocument: product?.billingDocument,
              creditNotesDocument: product?.creditNotesDocument,
              serviceId: product?.serviceId,
            };

            return (
              <Grid
                key={ndx}
                item
                container
                xs={12}
                style={{ padding: 20, marginBottom: 20, backgroundColor: grey }}
              >
                <Grid item xs={12}>
                  <AimTypography margin={0} variant={`h4`}>
                    {i18n.general.bookingInfo}
                  </AimTypography>
                </Grid>
                <Grid item xs={12}>
                  <Spacer pixels={5} />
                  <Divider />
                  <Spacer pixels={5} />
                  <AdditionalServiceBox
                    key={ndx}
                    {...{ data: product, i18n }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    paddingTop: 5,
                  }}
                >
                  <PrintPDFTicket
                    {...{
                      module: 'additionalServices',
                      clientInformations,
                      billingInformations,
                      paymentDetails,
                      additionalServicesInformations,
                      intl,
                      i18n,
                    }}
                  />
                </Grid>
              </Grid>
            );
          })}
      </Grid>
    </Grid>
  ) : (
    <></>
  );
};

const InfoAreaTransfer = ({ blockData, intl, clientInformations, i18n }) => {
  const data = blockData.data.length ? blockData.data : [];

  const transferInformations = blockData;

  return data.length ? (
    <Grid
      item
      xs={12}
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
      }}
    >
      <Grid container xs={12} style={{ margin: '10px 0px' }}>
        {data.map((transfer, ndx) => (
          <Grid
            key={ndx}
            item
            container
            xs={12}
            style={{ padding: 20, marginBottom: 20, backgroundColor: grey }}
          >
            <Grid item xs={12}>
              <AimTypography margin={0} variant={`h4`}>
                {i18n.general.bookingInfo}
              </AimTypography>
            </Grid>
            <Grid item xs={12}>
              <Spacer pixels={5} />
              <Divider />
              <Spacer pixels={5} />
              <TransferBox key={ndx} {...{ data: transfer, i18n, intl }} />
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  paddingTop: 5,
                }}
              >
                <PrintPDFTicket
                  {...{
                    module: 'transfer',
                    transferInformations: { transferInformations, ndx },
                    clientInformations,
                    intl,
                    i18n,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  ) : (
    <></>
  );
};

const InfoAreaTravel = ({
  blockData,
  intl,
  clientInformations,
  i18n,
  s3Folder,
}) => {
  const data = blockData.data.length ? blockData.data : [];

  const travelInformations = blockData;

  return data.length > 0 ? (
    <Grid
      item
      xs={12}
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
      }}
    >
      <Grid container xs={12} style={{ margin: '10px 0px' }}>
        {data
          .sort(
            (a, b) =>
              new Date(
                `${a.date}T${
                  a.travelRoute?.startDate ||
                  // to handle case of personal transport that not have return time
                  a.returnTrip === 'true'
                    ? '23:59'
                    : '00:00'
                }`
              ) -
              new Date(
                `${b.date}T${
                  b.travelRoute?.startDate ||
                  // to handle case of personal transport that not have return time
                  b.returnTrip === 'true'
                    ? '23:59'
                    : '00:00'
                }`
              )
          )
          .map((travel, ndx) => {
            return (
              <Grid
                key={ndx}
                item
                container
                xs={12}
                style={{ padding: 20, marginBottom: 20, backgroundColor: grey }}
              >
                <Grid item xs={12}>
                  <AimTypography margin={0} variant={`h4`}>
                    {i18n.general.bookingInfo}
                  </AimTypography>
                </Grid>
                <Grid item xs={12}>
                  <Spacer pixels={5} />
                  <TravelBox key={ndx} {...{ data: travel, i18n, intl }} />
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      paddingTop: 5,
                    }}
                  >
                    {travel.attachments.length > 0 ? (
                      <AimIconAndTextButton
                        variant="primary"
                        text={i18n.general.download}
                        onClick={() =>
                          downloadAttachmentOrZipIfMoreThanOne({
                            attachments: travel.attachments,
                            zipName: 'travel-attachments.zip',
                          })
                        }
                      >
                        <GetAppIcon />
                      </AimIconAndTextButton>
                    ) : null}
                    <PrintPDFTicket
                      {...{
                        module: 'travel',
                        travelInformations: { travelInformations, ndx },
                        clientInformations,
                        i18n,
                        intl,
                      }}
                    />
                    {travel.checkInLink ? (
                      <AimIconAndTextButton
                        text={i18n.general.checkIn}
                        onClick={() => window.open(travel.checkInLink, 'blank')}
                      >
                        <CardTravelIcon />
                      </AimIconAndTextButton>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
      </Grid>
    </Grid>
  ) : (
    <></>
  );
};

const renderInfoArea = (blockData, intl, clientInfo, i18n, s3Folder) => {
  switch (blockData.blockKey) {
    case 'ALLOTMENT':
      return (
        <InfoAreaAllotment
          {...{ blockData, intl, clientInformations: clientInfo, i18n }}
        />
      );
    case 'REGISTRATION':
      return (
        <InfoAreaRegistration
          {...{ blockData, intl, clientInformations: clientInfo, i18n }}
        />
      );
    case 'SOCIAL_EVENT':
      return (
        <InfoAreaAdditionaServices
          {...{ blockData, intl, clientInformations: clientInfo, i18n }}
        />
      );
    case 'SCIENTIFIC_EVENT':
      return (
        <InfoAreaAdditionaServices
          {...{ blockData, intl, clientInformations: clientInfo, i18n }}
        />
      );
    case 'TRANSFER':
      return (
        <InfoAreaTransfer
          {...{ blockData, intl, clientInformations: clientInfo, i18n }}
        />
      );
    case 'TRAVEL':
      return (
        <InfoAreaTravel
          {...{
            blockData,
            intl,
            clientInformations: clientInfo,
            i18n,
            s3Folder,
          }}
        />
      );
    case 'DOWNLOAD_AREA':
      return (
        <InfoDownloadArea
          {...{ blockData, intl, clientInformations: clientInfo, i18n }}
        />
      );

    default:
      return null;
  }
};

const Divider = () => {
  return <div style={{ backgroundColor: slightlyDarkerGrey, minHeight: 1 }} />;
};

const Spacer = ({ pixels }) => {
  return <div style={{ minHeight: pixels }} />;
};

const AllotmentBox = ({ data, i18n, intl }) => {
  return (
    <>
      <Spacer pixels={10} />
      <Divider />
      <Grid item xs={12} style={{ marginTop: 15 }}>
        <AimTypography margin={0} variant={`h4`}>
          {`${i18n.general.hotel}:`}
          <span style={{ fontWeight: 'normal' }}> {data?.hotelName}</span>
        </AimTypography>
      </Grid>
      <Grid item xs={12}>
        <AimTypography margin={0} variant={`h4`}>
          {`${i18n.general.hotelAddress}:`}
          <span style={{ fontWeight: 'normal' }}> {data?.hotelAddress}</span>
        </AimTypography>
      </Grid>
      <Grid item xs={12}>
        <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
          <div style={{ alignItems: 'center', display: 'flex' }}>
            <AimTypography margin={0} variant={`h4`}>
              {`${i18n.general.hotelStars}:`}
            </AimTypography>
          </div>
          <div style={{ alignItems: 'center', display: 'flex' }}>
            {[...Array(data?.hotelStars)].map((item, ndx) => (
              <GradeIcon key={`${`STAR_`}${ndx}`} style={{ color: 'orange' }} />
            ))}
          </div>
        </div>
      </Grid>
      <Grid item xs={12}>
        <AimTypography margin={0} variant={`h4`}>
          {`${i18n.general.hotelPhone}:`}
          <span style={{ fontWeight: 'normal' }}> {data?.hotelPhone}</span>
        </AimTypography>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 15 }}>
        <AimTypography margin={0} variant={`h4`}>
          {`${i18n.general.hotelEmail}:`}
          <span style={{ fontWeight: 'normal' }}> {data?.hotelEmail}</span>
        </AimTypography>
      </Grid>
      {data.hotelRoomReservation.map((hotelRoomSingleReservation, ndx) => {
        const guests = hotelRoomSingleReservation?.guests?.items?.map(
          (guest) => {
            return `${guest.givenName} ${guest.familyName}`;
          }
        );

        return (
          <Grid key={ndx} style={{ marginBottom: 20 }}>
            <Grid item xs={12}>
              <AimTypography margin={0} variant={`h4`}>
                {`${i18n.general.roomTypology}: `}
                <span style={{ fontWeight: 'normal' }}>
                  {hotelRoomSingleReservation.hotelRoom?.frontofficeName ||
                    hotelRoomSingleReservation.hotelRoom?.name}
                </span>
              </AimTypography>
            </Grid>
            <Grid key={ndx} item xs={12}>
              <AimTypography margin={0} variant={`h4`}>
                {`${i18n.general.guests}: `}
                <span style={{ fontWeight: 'normal' }}>
                  {guests.join(', ')}
                </span>
              </AimTypography>
            </Grid>
            <Grid key={ndx} item xs={12}>
              <AimTypography margin={0} variant={`h4`}>
                {`${i18n.general.checkIn}: `}
                <span style={{ fontWeight: 'normal' }}>
                  {formatDate(hotelRoomSingleReservation?.startDate)}
                </span>
              </AimTypography>
            </Grid>
            <Grid key={ndx} item xs={12}>
              <AimTypography margin={0} variant={`h4`}>
                {`${i18n.general.checkOut}: `}
                <span style={{ fontWeight: 'normal' }}>
                  {formatDate(hotelRoomSingleReservation?.endDate)}
                </span>
              </AimTypography>
            </Grid>
            {hotelRoomSingleReservation.treatment ? (
              <Grid key={ndx} item xs={12}>
                <AimTypography margin={0} variant={`h4`}>
                  {`${i18n.general.hotelTreatment}: `}
                  <span style={{ fontWeight: 'normal' }}>
                    {Object.values(constants.HotelTreatments)
                      .find(
                        ({ key }) =>
                          key === hotelRoomSingleReservation.treatment
                      )
                      ?.label(intl)}
                  </span>
                </AimTypography>
              </Grid>
            ) : null}
          </Grid>
        );
      })}
    </>
  );
};

const TravelBox = ({ data, i18n, intl }) => {
  const guests = data?.guests?.items?.map((guest) => {
    return `${guest.givenName} ${guest.familyName}`;
  });

  return (
    <>
      <Spacer pixels={10} />
      <Divider />
      <Grid item container xs={12} style={{ marginTop: 15 }}>
        <Grid item xs={12}>
          {/* travel type */}
          <AimTypography margin={0} variant={`h4`}>
            {`${i18n.general.typeOfTravel}: `}
            <span style={{ fontWeight: 'normal' }}>
              {/* {ucwords(data?.travelRoute?.travelType)} */}
              {Object.values(constants.TravelTypes)
                .find((t) => t.id === data?.travelRoute?.travelType)
                ?.label(intl)}
            </span>
          </AimTypography>
        </Grid>

        {/* note only for personal transport type */}
        {data?.travelRoute?.travelType ===
          constants.TravelTypes.PERSONALTRANSPORT.id && (
          <Grid item xs={12}>
            <AimTypography
              variant="text"
              style={{ margin: '10 0', fontStyle: 'italic' }}
            >
              {i18n.general.personalTransportDescr}
            </AimTypography>
          </Grid>
        )}

        {/* travel code */}
        {data?.travelRoute?.transportCode !== '' && (
          <Grid item xs={12}>
            <AimTypography margin={0} variant={`h4`}>
              {`${i18n.general.travelCode}: `}
              <span style={{ fontWeight: 'normal' }}>
                {data?.travelRoute?.transportCode.toUpperCase()}
              </span>
            </AimTypography>
          </Grid>
        )}

        {/* A/R */}
        <Grid item xs={12}>
          <AimTypography margin={0} variant={`h4`}>
            {`${i18n.general.aR}: `}
            <span style={{ fontWeight: 'normal' }}>
              {data.returnTrip === 'true' ? 'R' : 'A'}
            </span>
          </AimTypography>
        </Grid>

        {/* guests */}
        {guests?.length > 0 && (
          <Grid item xs={12}>
            <AimTypography margin={0} variant={`h4`}>
              {`${i18n.general.accompanyingPersons}:`}{' '}
              <span style={{ fontWeight: 'normal' }}>{guests.join(', ')}</span>
            </AimTypography>
          </Grid>
        )}

        {/* terminal in */}
        {data?.travelRoute?.terminalIn &&
          data?.travelRoute?.terminalIn?.name !== '' && (
            <Grid item xs={12}>
              <AimTypography margin={0} variant={`h4`}>
                {`${i18n.general.departureFrom}: `}
                <span style={{ fontWeight: 'normal' }}>
                  {data?.travelRoute?.terminalIn?.name}
                </span>
              </AimTypography>
            </Grid>
          )}

        {/* departure date */}
        {data?.date && (
          <Grid item xs={12}>
            <AimTypography margin={0} variant={`h4`}>
              {`${i18n.general.departureDate}: `}
              <span style={{ fontWeight: 'normal' }}>
                {data.date ? formatDate(data?.date) : '-'}
              </span>
            </AimTypography>
          </Grid>
        )}

        {/* departure time */}
        {data?.travelRoute?.startDate && (
          <Grid item xs={12}>
            <AimTypography margin={0} variant={`h4`}>
              {`${i18n.general.departureTime}: `}
              <span style={{ fontWeight: 'normal' }}>
                {data?.travelRoute?.startDate
                  ? data?.travelRoute?.startDate
                  : '-'}
              </span>
            </AimTypography>
          </Grid>
        )}

        {/* terminal out */}
        {data?.travelRoute?.terminalOut &&
          data?.travelRoute?.terminalOut?.name !== '' && (
            <Grid item xs={12}>
              <AimTypography margin={0} variant={`h4`}>
                {`${i18n.general.arrivalTo}: `}
                <span style={{ fontWeight: 'normal' }}>
                  {data?.travelRoute?.terminalOut?.name}
                </span>
              </AimTypography>
            </Grid>
          )}

        {/* arrival date */}
        {data?.arrivalDate && (
          <Grid item xs={12}>
            <AimTypography margin={0} variant={`h4`}>
              {`${i18n.general.arrivalDate}: `}
              <span style={{ fontWeight: 'normal' }}>
                {data?.arrivalDate ? formatDate(data?.arrivalDate) : '-'}
              </span>
            </AimTypography>
          </Grid>
        )}

        {/* arrival time */}
        {data?.travelRoute?.endDate && (
          <Grid item xs={12}>
            <AimTypography margin={0} variant={`h4`}>
              {`${i18n.general.arrivalTime}: `}
              <span style={{ fontWeight: 'normal' }}>
                {data?.travelRoute?.endDate ? data?.travelRoute?.endDate : '-'}
              </span>
            </AimTypography>
          </Grid>
        )}

        {/* prn code */}
        {data?.pnrCode && (
          <Grid item xs={12}>
            <AimTypography margin={0} variant={`h4`}>
              {`${i18n.general.pnr}: `}
              <span style={{ fontWeight: 'normal' }}>{data?.pnrCode}</span>
            </AimTypography>
          </Grid>
        )}
      </Grid>
    </>
  );
};

const RegistrationBox = ({ data, i18n }) => {
  return (
    <>
      <Grid item xs={12} style={{ marginTop: 15 }}>
        <AimTypography margin={0} variant={`h4`}>
          {`${i18n.general.registration}:`}
          <span style={{ fontWeight: 'normal' }}>
            {data.ticket.feeBracket.feeDateRange.label}
          </span>
        </AimTypography>
        <AimTypography margin={0} variant={`h4`}>
          {`${i18n.general.start}:`}
          <span style={{ fontWeight: 'normal' }}>
            {formatDate(data.ticket.feeBracket.feeDateRange.start)}
          </span>
        </AimTypography>
        <AimTypography margin={0} variant={`h4`}>
          {`${i18n.general.end}:`}
          <span style={{ fontWeight: 'normal' }}>
            {formatDate(data.ticket.feeBracket.feeDateRange.end)}
          </span>
        </AimTypography>
      </Grid>
    </>
  );
};

const AdditionalServiceBox = ({ data, i18n }) => {
  return (
    <>
      <Grid item xs={12} style={{ marginTop: 15 }}>
        <AimTypography margin={0} variant={`h4`}>
          {`${i18n.general.event}:`}
          <span style={{ fontWeight: 'normal' }}>
            {data.additionalService.title}
          </span>
        </AimTypography>
        <AimTypography margin={0} variant={`h4`}>
          {`${i18n.general.description}:`}
          <span style={{ fontWeight: 'normal' }}>
            {data.additionalService.description}
          </span>
        </AimTypography>
        <AimTypography margin={0} variant={`h4`}>
          {`${i18n.general.date}:`}
          <span style={{ fontWeight: 'normal' }}>
            {formatDate(data.additionalService.dateService)}
          </span>
        </AimTypography>
        <AimTypography margin={0} variant={`h4`}>
          {`${i18n.general.startTime}:`}
          <span style={{ fontWeight: 'normal' }}>
            {data.additionalService.startTime}
          </span>
        </AimTypography>
        <AimTypography margin={0} variant={`h4`}>
          {`${i18n.general.endTime}:`}
          <span style={{ fontWeight: 'normal' }}>
            {data.additionalService.endTime}
          </span>
        </AimTypography>
        {data.additionalService.locationName ? (
          <AimTypography margin={0} variant={`h4`}>
            {`${i18n.general.location}:`}
            <span style={{ fontWeight: 'normal' }}>
              {data.additionalService.locationName}
            </span>
          </AimTypography>
        ) : (
          <></>
        )}
        {data.additionalService.locationAddress ? (
          <AimTypography margin={0} variant={`h4`}>
            {`${i18n.general.address}:`}
            <span style={{ fontWeight: 'normal' }}>
              {data.additionalService.locationAddress}
            </span>
          </AimTypography>
        ) : (
          <></>
        )}
      </Grid>
    </>
  );
};

const TransferBox = ({ data, i18n, intl }) => {
  return (
    <>
      <Grid item xs={12} style={{ marginTop: 15 }}>
        <AimTypography margin={0} variant={`h4`}>
          {`${i18n.general.date}:`}
          <span style={{ fontWeight: 'normal' }}>{formatDate(data.date)}</span>
        </AimTypography>
        <AimTypography margin={0} variant={`h4`}>
          {`${i18n.general.from}:`}
          <span style={{ fontWeight: 'normal' }}>
            {`${Object.values(constants.DestinationTravelTypes)
              .find((o) => o.id === data.from)
              ?.label(intl)}${
              data.pickUpLocation ? `, ${data.pickUpLocation}` : ''
            }`}
          </span>
        </AimTypography>
        <AimTypography margin={0} variant={`h4`}>
          {`${i18n.general.time}:`}
          <span style={{ fontWeight: 'normal' }}>{data.time}</span>
        </AimTypography>
        <AimTypography margin={0} variant={`h4`}>
          {`${i18n.general.to}:`}
          <span style={{ fontWeight: 'normal' }}>
            {`${Object.values(constants.DestinationTravelTypes)
              .find((o) => o.id === data.to)
              ?.label(intl)}${
              data.pickUpDestination ? `, ${data.pickUpDestination}` : ''
            }`}
          </span>
        </AimTypography>
      </Grid>
    </>
  );
};

const ClientBox = ({ givenName, familyName, phone, email, i18n }) => {
  return (
    <>
      <Grid item xs={12}>
        <AimTypography margin={0} variant={`h4`}>
          {`${i18n.client.name}:`}{' '}
          <span style={{ fontWeight: 'normal' }}>{givenName || ''}</span>
        </AimTypography>
      </Grid>
      <Grid item xs={12}>
        <AimTypography margin={0} variant={`h4`}>
          {`${i18n.client.surname}:`}{' '}
          <span style={{ fontWeight: 'normal' }}>{familyName || ''}</span>
        </AimTypography>
      </Grid>
      <Grid item xs={12}>
        <AimTypography margin={0} variant={`h4`}>
          {`${i18n.client.phone}:`}{' '}
          <span style={{ fontWeight: 'normal' }}>{phone || ''}</span>
        </AimTypography>
      </Grid>
      <Grid item xs={12}>
        <AimTypography margin={0} variant={`h4`}>
          {`${i18n.client.email}:`}{' '}
          <span style={{ fontWeight: 'normal' }}>{email || ''}</span>
        </AimTypography>
      </Grid>
    </>
  );
};

const toggleBlock = ({ blocks, blockKey, setBlocks }) => {
  const __blocks = [
    ...blocks.map((block) => {
      return {
        ...block,
        selected: false,
      };
    }),
  ];
  __blocks.find((x) => x.blockKey === blockKey).selected = true;
  setBlocks(__blocks);
};

const Block = ({ blockData, blocks, setBlocks, onClick }) => {
  const { title, blockKey, selected } = blockData;
  return (
    <Grid
      item
      container
      md={4}
      sm={6}
      xs={12}
      // style={{ maxWidth: 300 }}
      // style={{
      //   ...{
      //     ...(blockData.isActive && { cursor: 'pointer' }),
      //   },
      // }}
      style={{ cursor: 'pointer' }}
      onClick={() => {
        if (onClick) {
          onClick();
        } else if (blockData.onClick) {
          blockData.onClick();
        } else {
          toggleBlock({ blocks, blockKey, setBlocks });
        }
      }}
    >
      <Grid
        item
        xs={12}
        style={{
          backgroundColor: grey,
          height: 103, // from xd file
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          ...(selected && { border: '1px black solid' }),
        }}
      >
        <AimTypography margin={0} variant={`h3`}>
          {title}
        </AimTypography>
      </Grid>
    </Grid>
  );
};

const Blocks = ({ blocks, setBlocks }) => {
  return (
    <Grid
      container
      spacing={10}
      style={{ display: 'flex', justifyContent: 'flex-start' }}
    >
      {blocks
        .filter(
          (block) => block.data?.length || Object.keys(block.data || {}).length
        )
        .map((block, ndx) => {
          return (
            <Block key={ndx} {...{ blockData: block, blocks, setBlocks }} />
          );
        })}
    </Grid>
  );
};

const ButtonBlocks = ({ blocks }) => {
  return (
    <Grid
      container
      spacing={10}
      style={{ display: 'flex', justifyContent: 'flex-start' }}
    >
      {blocks
        .filter((block) => block.data)
        .map((block, ndx) => {
          return (
            <Block
              key={ndx}
              {...{ blockData: block, blocks, onClick: block.handleClick }}
            />
          );
        })}
    </Grid>
  );
};

// const propertiesToCheck = [
//   'ALLOTMENT',
//   'REGISTRATION',
//   'SCIENTIFIC_EVENT',
//   'SOCIAL_EVENT',
//   // 'travel',
//   // 'transfer'
// ];

let { Services } = constants;
// Services = Object.fromEntries(
//   Object.entries(Services).filter(
//     ([key]) => propertiesToCheck.indexOf(key) > -1
//   )
// );

const mapDataToBlocks = ({ data, setBlocks, intl }) => {
  // propertiesToCheck.forEach((property) => {
  //   if (data[property].length > 0) {
  //     arrayOfBlocks.push({
  //       title: Services[property].label(intl),
  //       selected: false,
  //       blockKey: property,
  //     });
  //   }
  // });

  const ALLOTMENT_data = {
    title: Services['ALLOTMENT'].label(intl),
    selected: false,
    blockKey: 'ALLOTMENT',
    data: data.ALLOTMENT.map((hotel) => {
      return {
        ...hotel,
        hotelName:
          hotel?.hotel?.hotelRoomReservation?.items?.[0].hotelRoom?.hotel
            ?.name || '-',
        hotelAddress:
          `${
            hotel?.hotel?.hotelRoomReservation?.items?.[0].hotelRoom?.hotel
              ?.address
          } ${
            hotel?.hotel?.hotelRoomReservation?.items?.[0].hotelRoom?.hotel
              ?.streetNumber || ''
          }` || '-',
        hotelStars:
          hotel?.hotel?.hotelRoomReservation?.items?.[0].hotelRoom?.hotel
            ?.stars || '-',
        hotelPhone:
          hotel?.hotel?.hotelRoomReservation?.items?.[0].hotelRoom?.hotel
            ?.phone || '-',
        hotelEmail:
          hotel?.hotel?.hotelRoomReservation?.items?.[0].hotelRoom?.hotel
            ?.email || '-',
        hotelRoomReservation: hotel?.hotel?.hotelRoomReservation?.items || '-',
      };
    }),
  };

  const REGISTRATION_data = {
    title: Services.REGISTRATION.label(intl),
    selected: false,
    blockKey: 'REGISTRATION',
    data: data.REGISTRATION,
  };

  const SOCIAL_EVENT_data = {
    title: 'Social Events', //Services.SOCIAL_EVENT.label(intl),
    selected: false,
    blockKey: 'SOCIAL_EVENT',
    data: data.SOCIAL_EVENT,
  };
  const SCIENTIFIC_EVENT_data = {
    title: 'Scientific Events', //Services.SCIENTIFIC_EVENT.label(intl),
    selected: false,
    blockKey: 'SCIENTIFIC_EVENT',
    data: data.SCIENTIFIC_EVENT,
  };
  const TRANSFER_data = {
    title: 'Transfer', //Services.SCIENTIFIC_EVENT.label(intl),
    selected: false,
    blockKey: 'TRANSFER',
    data: data.transfer,
  };

  const TRAVEL_data = {
    title: 'Travel',
    selected: false,
    blockKey: 'TRAVEL',
    data: data.travel,
  };
  const DOWNLOAD_AREA_data = {
    title: 'Download Area',
    selected: false,
    blockKey: 'DOWNLOAD_AREA',
    data: data.downloadArea,
  };

  const arrayOfBlocks = [
    ALLOTMENT_data,
    REGISTRATION_data,
    SOCIAL_EVENT_data,
    SCIENTIFIC_EVENT_data,
    TRANSFER_data,
    TRAVEL_data,
    DOWNLOAD_AREA_data,
  ];

  setBlocks(arrayOfBlocks);
  hideLoader();
};

const mapDataToButtons = ({
  data,
  setButtonsBlock,
  setDataToShow,
  history,
  eventId,
  participationServiceId,
  paramsString,
}) => {
  const GENERAL_INFO = {
    title: 'General Info',
    selected: false,
    blockKey: 'GENERAL_INFO',
    data: data?.letter,
    handleClick: () =>
      setDataToShow({
        type: 'GENERAL_INFO',
        data: data?.letter,
        participation: data,
        preTitles: data.preTitles?.items,
      }),
  };

  const WELCOME_data = {
    title: 'Welcome',
    selected: false,
    blockKey: 'WELCOME',
    data: data.WELCOME,
    onClick: () =>
      history.push(
        `/events/${eventId}/my-services/${participationServiceId}/welcome${paramsString}`
      ),
  };

  const newButtonsBlock = [
    ...(data?.letter ? [GENERAL_INFO] : []),
    ...(Object.keys(data?.WELCOME).length > 0 ? [WELCOME_data] : []),
  ];
  setButtonsBlock(newButtonsBlock);
};

const ParticipationServicesPublicPage = () => {
  const location = useLocation();
  const history = useHistory();
  const { eventId, participationServiceId } = useParams();

  const intl = CustomIntl(useIntl());
  const i18n = translation.participationServicesPublicPage(intl);

  // const [data, setData] = useState();
  // const [dataType, setDataType] = useState('default');
  // const [configuration] = useState(appState.eventConfiguration.getValue());
  const [eventInfo] = useState(appState.eventInfo.getValue());

  // if (!data || !eventInfo) {
  //   return null;
  // }

  const [blocks, setBlocks] = useState([]);
  const [buttonsBlocks, setButtonsBlock] = useState([]);
  const [clientInfo, setClientInfo] = useState({});
  const [dataReady, setDataReady] = useState();
  const [dataToShow, setDataToShow] = useState();
  const s3Folder = `events/${eventId}/participations/${clientInfo.id}/`;
  const [layoutTemplate, setLayoutTemplate] = useState([]);

  const downloadLinkImage = (item, letterLayoutTemplateId) => {
    // const s3Folder = `events/${eventId}/letter/template/blocks/`;
    const s3Folder = `letter/template/${letterLayoutTemplateId}`;
    console.log(
      'download link ',
      fileHelper.getPublicFileLink({ dirPath: s3Folder, fileData: item })
    );
    // const link = `${s3Folder}${item.id}${item.extension}`;
    return fileHelper.getPublicFileLink({ dirPath: s3Folder, fileData: item });
  };

  useEffect(() => {
    if (blocks.length > 0) {
      setDataReady(true);
    }
  }, [blocks]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get('code');

    const fetchData = async () => {
      const res = await aws.standardAPI.post(
        'aimlambdaproxy',
        '/admin/public-services',
        {
          body: {
            eventId,
            participationServiceId,
            code,
          },
        }
      );
      return res;
    };

    const fetchEventLetterLayoutTemplate = async () => {
      console.log('fetchEventLetterLayoutTemplate ');
      const awsUser = appState.user.getValue()?.userAndParticipation
        ?.participation.id;
      const layoutTemplateRes = await getEventLetterLayoutTemplate(
        eventId,
        !!awsUser
      );
      console.log('layoutTemplate ', layoutTemplateRes);

      const layoutTemplateHeader = layoutTemplateRes?.header?.items.map(
        (item) => ({
          ...item,
          imageUrl: downloadLinkImage(item.image, layoutTemplateRes.id),
        })
      );
      const layoutTemplateFooter = layoutTemplateRes?.footer?.items.map(
        (item) => ({
          ...item,
          imageUrl: downloadLinkImage(item.image, layoutTemplateRes.id),
        })
      );

      setLayoutTemplate({
        ...layoutTemplateRes,
        header: layoutTemplateHeader,
        footer: layoutTemplateFooter,
      });
    };

    showLoader();

    fetchData().then((result) => {
      const { id, givenName, familyName, email, phone } = result;
      setClientInfo({
        id,
        givenName,
        familyName,
        email,
        phone,
      });
      mapDataToBlocks({
        data: result,
        setBlocks,
        intl,
      });
      mapDataToButtons({
        data: result,
        setButtonsBlock,
        intl,
        setDataToShow,
        history,
        eventId,
        participationServiceId,
        paramsString: location.search,
      });

      fetchEventLetterLayoutTemplate();
    });
  }, []);

  // console.log('dataToShow?.data', dataToShow?.data);

  const BackButton = () => {
    return (
      <div style={{ display: 'flex', flex: 1 }}>
        <AimIconAndTextButton
          style={{
            padding: 0,
          }}
          variant="none"
          text={i18n.generalInfo.backButtonLabel}
          onClick={() => setDataToShow(null)}
        >
          <ArrowBack />
        </AimIconAndTextButton>
      </div>
    );
  };

  return dataToShow?.type === 'GENERAL_INFO' ? (
    <MainContainer>
      <div>
        <BackButton />
        <br />
        <LetterLayoutTemplate
          eventId={eventId}
          headerBlocks={layoutTemplate?.header}
          footerBlocks={layoutTemplate?.footer}
        >
          <GeneralInfoContent
            data={dataToShow?.data}
            participation={dataToShow?.participation}
            preTitles={dataToShow?.preTitles}
          />
        </LetterLayoutTemplate>
      </div>
    </MainContainer>
  ) : (
    <MainContainer>
      <Grid
        item
        xs={12}
        style={{ display: 'flex', justifyContent: 'flex-start' }}
      >
        <AimTypography variant={`h1`}>{eventInfo.name}</AimTypography>
      </Grid>
      <Grid
        item
        xs={12}
        style={{ display: 'flex', justifyContent: 'flex-start' }}
      >
        <AimTypography variant={`h4`}>{i18n.general.welcomeTo}</AimTypography>
      </Grid>
      <Spacer pixels={20} />
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          backgroundColor: grey,
          paddingTop: 10,
          paddingBottom: 10,
          paddingLeft: 20,
          paddingRight: 20,
        }}
      >
        <Grid item container xs={12} style={{ padding: 5 }}>
          <Grid item xs={12}>
            <AimTypography margin={0} variant={`h4`}>
              {i18n.general.clientInfo}
            </AimTypography>
          </Grid>
          <Grid item xs={12}>
            <Spacer pixels={5} />
            <Divider />
            <Spacer pixels={5} />
            <ClientBox
              {...{
                givenName: clientInfo.givenName,
                familyName: clientInfo.familyName,
                email: clientInfo.email,
                phone: clientInfo.phone,
                i18n,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Spacer pixels={20} />
      {buttonsBlocks?.length ? (
        <>
          <Grid
            item
            xs={12}
            style={{ display: 'flex', justifyContent: 'flex-start' }}
          >
            <AimTypography variant={`h4`}>
              {i18n.general.clickOnButton}
            </AimTypography>
          </Grid>
          <Spacer pixels={20} />
          <ButtonBlocks {...{ blocks: buttonsBlocks }} />
          <Spacer pixels={20} />
        </>
      ) : null}
      <Grid
        item
        xs={12}
        style={{ display: 'flex', justifyContent: 'flex-start' }}
      >
        <AimTypography variant={`h4`}>
          {i18n.general.clickOnTheSection}
        </AimTypography>
      </Grid>
      <Spacer pixels={20} />
      <Blocks {...{ blocks, setBlocks }} />
      {blocks.findIndex((x) => x.selected) > -1 && (
        <>
          <Spacer pixels={20} />
          {dataReady &&
            renderInfoArea(
              blocks.find((x) => x.selected),
              intl,
              clientInfo,
              i18n,
              s3Folder
            )}
        </>
      )}
    </MainContainer>
  );
};

export default ParticipationServicesPublicPage;
