import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useForm, Controller } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import { constants, utilities } from '@aim/common';
import {
  AimSelectForm,
  AimSelectMenuItem,
  AimTypography,
  CustomIntl,
  theme,
  styled,
} from '@aim/components';
import translation from './translation';
import { getEventAdditionalServices } from './gqlHelper';
import { isEqual, isWithinInterval } from 'date-fns';

const formControlStyle = { width: 'calc(100% - 10px' };

const propsPriceNamesByParticipationMode = {
  [constants.EventTypes.PHYSICAL.id]: 'priceOnSite',
  [constants.EventTypes.VIRTUAL.id]: 'priceOnAir',
};

const CustomFormControl = styled(({ m = 1, p, ...other }) => (
  <FormControl {...other} />
))({
  margin: (props) => theme.spacing(props.m || 0),
  padding: (props) => (props.p ? theme.spacing(props.p) : undefined),
  minWidth: 120,
  flex: 1,
});

const setPriceValue = ({
  setValue,
  allAdditionalServices,
  selectedAdditionalServices,
}) => {
  try {
    const additionalServices = selectedAdditionalServices.map((sas) =>
      allAdditionalServices.find((as) => as.id === sas)
    );

    if (additionalServices.length) {
      const price = additionalServices.reduce((acc, additionalService) => {
        return (
          acc +
          utilities.calculateTotalPriceAdditionalService(additionalService)
        );
      }, 0);
      setValue(`price`, price);
    } else {
      setValue(`price`, 0);
    }
  } catch (error) {
    console.error(error);
  }
};

const AdditionalServicesForm = ({
  additionalServicesFormRef,
  cluster,
  typology,
  participation,
}) => {
  const { eventId } = useParams();
  const intl = CustomIntl(useIntl());
  const i18n = translation.additionalServicesForm(intl);
  const [
    availableAdditionalServices,
    setAvailableAdditionalServices,
  ] = useState([]);
  const [allAdditionalServices, setAllAdditionalServices] = useState([]);
  const [isAdditionalServicesLoaded, setIsAdditionalServicesLoaded] = useState(
    false
  );
  const [profile, setProfile] = useState('');
  const [participationMode, setParticipationMode] = useState('');
  const [profileFeeBracketId, setProfileFeeBracketId] = useState('');
  const {
    control,
    setValue,
    watch,
    errors,
    trigger,
    getValues,
    reset,
  } = useForm({
    defaultValues: {
      additionalServices: [],
      price: '',
    },
  });
  const selectedAdditionalServices = watch('additionalServices');

  useEffect(() => {
    if (!isAdditionalServicesLoaded) return;
    reset({
      additionalServices: participation.n2nConnection.items.map(
        ({ n2NConnectionsTableAdditionalServicesId }) =>
          n2NConnectionsTableAdditionalServicesId
      ),
    });
  }, [participation, isAdditionalServicesLoaded]);

  useEffect(() => {
    const loadData = async () => {
      const nextAdditionalServices = await getEventAdditionalServices({
        eventId,
        typology,
      });
      setAllAdditionalServices(nextAdditionalServices);
      setIsAdditionalServicesLoaded(true);
    };
    loadData();
  }, []);

  useEffect(() => {
    additionalServicesFormRef.current = {
      trigger,
      getValues,
      setProfile,
      setParticipationMode,
      setProfileFeeBracketId,
    };
  }, [trigger, getValues]);

  useEffect(() => {
    if (
      availableAdditionalServices.length > 0 &&
      (!cluster || !profile || !allAdditionalServices.length)
    ) {
      setAvailableAdditionalServices([]);
      setValue('additionalServices', []);
    } else {
      const nextAvailableAdditionalServices = allAdditionalServices.filter(
        (item) =>
          (!item.participationType ||
            !participationMode ||
            item.participationType === participationMode) &&
          ((!item.reservedToCluster && !item.reservedToProfiles) ||
            (item.reservedToProfiles &&
              item.reservedToProfiles.includes(profile)) ||
            (!item.reservedToProfiles &&
              item.reservedToCluster &&
              item.reservedToCluster === cluster))
      );
      setAvailableAdditionalServices(nextAvailableAdditionalServices);
      setValue(
        'additionalServices',
        selectedAdditionalServices.filter((as) =>
          nextAvailableAdditionalServices.find((naas) => naas.id === as)
        )
      );
    }
  }, [cluster, profile, participationMode, allAdditionalServices]);

  useEffect(() => {
    setPriceValue({
      setValue,
      allAdditionalServices,
      selectedAdditionalServices,
    });
  }, [selectedAdditionalServices]);

  return (
    <Grid container>
      <Grid item xs={12} sm={6}>
        <AimSelectForm
          p={1}
          errors={errors}
          selectVariant="secondary"
          disabled={!profileFeeBracketId || !availableAdditionalServices.length}
          control={control}
          name="additionalServices"
          multiple
          label={
            typology ===
            constants.AdditionalServicesServiceTypology.scientific.id
              ? i18n.scientificEvent
              : i18n.socialEvent
          }
          formControlStyle={formControlStyle}
        >
          {availableAdditionalServices?.map((item) => (
            <AimSelectMenuItem key={item.id} value={item.id}>
              {item.title}
            </AimSelectMenuItem>
          ))}
        </AimSelectForm>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          name="price"
          control={control}
          render={(props) => {
            return (
              <CustomFormControl
                style={{ ...formControlStyle, margin: 8, padding: 8 }}
              >
                <FormLabel
                  style={{
                    marginBottom: 5,
                    paddingBottom: 0,
                    color: 'black',
                    fontWeight: 'bold',
                    fontSize: 14,
                    fontFamily: 'hind',
                    '&.MuiFormLabel-root.Mui-focused': {
                      color: 'black',
                    },
                  }}
                >
                  {i18n.totalAmount}
                </FormLabel>
                <AimTypography
                  variant="text"
                  style={{
                    backgroundColor: 'white',
                    height: 48,
                    borderRadius: 5,
                    padding: '0 10px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {utilities.formatNumber(utilities.safeNum(props.value))}
                </AimTypography>
              </CustomFormControl>
            );
          }}
        />
      </Grid>
    </Grid>
  );
};

export default AdditionalServicesForm;
