import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useIntl } from 'react-intl';
import {
  Switch,
  Route,
  useParams,
  useLocation,
  useHistory,
} from 'react-router-dom';
import PubNub from 'pubnub';
import { PubNubProvider } from 'pubnub-react';

import { appState, aws } from '@aim/common';
import { CustomIntl } from '@aim/components';

import WebcastCardGrid from './pages/WebcastCardGrid';
import AgendaEventDetail from './shared/agenda/AgendaEventDetail';
import Home from './pages/Home';
import AgencyRegistrationForm from './pages/agency/agencyRegistration/AgencyRegistrationForm';
import SponsorRegistrationForm from './pages/sponsor/sponsorRegistration/SponsorRegistrationForm';
import PaxRegistrationForm from './pages/participation/PaxRegistrationForm';
import RegistrationType from './pages/participation/RegistrationType';
import SignIn from './pages/login/SignIn';
import SignUp from './pages/login/SignUp';
import IdentityVerification from './pages/login/IdentityVerification';
import Landing from './pages/Landing';
import Grants from './pages/Grants';
import ParticipationServicesPublicPage from './pages/ParticipationServicesPublicPage';
import ParticipationWelcomePublicPage from './pages/ParticipationWelcomePublicPage';
import GenericPaymentsPublicPage from './pages/GenericPaymentsPublicPage';
import GenericPaymentsBillingInformationsPublicPage from './pages/GenericPaymentsBillingInformationsPublicPage';
import GenericPaymentsCartPublicPage from './pages/GenericPaymentsCartPublicPage';
import GenericPaymentsCheckoutResponse from './pages/GenericPaymentsCheckoutResponse';
import ProgramPublicPage from './pages/ProgramPublicPage';

import ForgotPassword from './pages/login/ForgotPassword';
import ForgotUsername from './pages/login/ForgotUsername';

import ResetTemporaryPassword from './pages/login/ResetTemporaryPassword';
import BasePage from './pages/login/BasePage';
import Layout from './pages/shared/layout/Layout';
import AgendaList from './shared/agenda/AgendaList';
import WebcastCardDetail from './pages/WebcastCardDetail';
import SurveyPage from './pages/SurveyPage';
import PublicSurveyPage from './pages/PublicSurveyPage';
import Surveys from './pages/profile/Surveys';
import Services from './pages/profile/Services';
import Certifications from './pages/profile/Certifications';
import UserAdditionalServices from './pages/profile/UserAdditionalServices';
import Sponsors from './pages/Sponsors';
import Speakers from './pages/Speakers';
import SponsorDetail from './pages/SponsorDetail';
import SponsorDetailEdit from './pages/SponsorDetailEdit';
import SponsorBookingAppointment from './pages/SponsorBookingAppointment';
import SponsorBookingConfirmAppointment from './pages/SponsorBookingConfirmAppointment';
import PaxAbstracts from './pages/PaxAbstracts';
import PresenterAbstracts from './pages/PresenterAbstracts';
import PaxAbstractsRoleSelection from './pages/PaxAbstractsRoleSelection';
import PaxAbstractsCategoriesSelection from './pages/PaxAbstractsCategoriesSelection';
import AbstractDetail from './pages/AbstractDetail';
import AbstractDetailReview from './pages/AbstractDetailReview';
import PaxAbstractsPresentation from './pages/PaxAbstractsPresentation';
import PriceRangesList from './pages/PriceRangesList';
import MyServices from './pages/MyServices';
import SponsorCart from './pages/SponsorCart';
import CheckoutSuccess from './pages/CheckoutSuccess';
import CheckoutError from './pages/CheckoutError';
import MyPurchases from './pages/MyPurchases';
import MyAgencyPurchases from './pages/MyAgencyPurchases';
import WallDisabiguation from './pages/myServices/WallDisambiguation';
import PaxAbstractsEdit from './pages/PaxAbstractsEdit';
import ReviewersAbstracts from './pages/ReviewersAbstracts';
import ReviewersAbstractsWall from './pages/ReviewersAbstractsWall';
import ReviewerCategory from './pages/ReviewerCategory';
import SponsorServiceConfigurationWall from './pages/SponsorServiceConfigurationWall';
import { ContentPageFrontendTemplate } from '@aim/components';
import Sessions from './pages/Sessions';
import ProfileEdit from './pages/ProfileEdit';
import BreakoutRoomList from './pages/sponsorServiceConfigurationWall/serviceList/BreakoutRoomList';
import BreakoutRoomConfiguration from './pages/sponsorServiceConfigurationWall/services/BreakoutRoomConfiguration';
import BreakoutRoomReservations from './pages/sponsorServiceConfigurationWall/services/BreakoutRoomReservations';
import BreakoutRoomInvite from './pages/sponsorServiceConfigurationWall/services/BreakoutRoomInvite';
import BreakoutRoomSendDelete from './pages/sponsorServiceConfigurationWall/services/BreakoutRoomSendDelete';
import BreakoutRoomSlotDetail from './pages/breakoutRoom/BreakoutRoomSlotDetail';
import MailLandingPage from './pages/breakoutRoom/MailLandingPage';
import PhysicalStandFloorsList from './pages/myServices/PhysicalStandFloorsList';
import PhysicalStandCategoriesList from './pages/sponsorServiceConfigurationWall/services/PhysicalStandCategoriesList';
import { PhysicalStandSpaces } from './pages/sponsorServiceConfigurationWall/services/PhysicalStandSpaces';
import PhysicalStandItemsList from './pages/sponsorServiceConfigurationWall/services/PhysicalStandItemsList';
import { VirtualStands } from './pages/sponsorServiceConfigurationWall/services/VirtualStands';
import SponsorStaffDetail from './pages/SponsorStaffDetail';
import {
  loadEventData,
  loadUserData,
  onUpdateParticipationId,
} from './shared/utilities';
import ParticipationEditWrapperFrontoffice from './pages/ParticipationEditWrapperFrontoffice.js';
import TableTest from './pages/TableTest';
import AdditionalService from './pages/AdditionalService';
import AdditionalServices from './pages/AdditionalServices';
import SponsorListDetails from './pages/SponsorListDetails';
import AgencyHomepage from './pages/AgencyHomepage';
import AgencyGroupDetail from './pages/AgencyGroupDetail';
import SponsorPackageDetails from './pages/myServices/SponsorPackageDetails';
import AgencyCartBillingInformations from './pages/agency/agencyPayment/AgencyCartBillingInformations';
import AgencyPaymentOptions from './pages/agency/agencyPayment/AgencyPaymentOptions';
import AgencyPayment from './pages/agency/agencyPayment/AgencyPayment';
import ImportFromFileFrontofficeWrapper from './pages/ImportFromFileFrontofficeWrapper';
import Visa from './pages/Visa';
import PosterDetail from './shared/agenda/PosterDetail';
import InterventionAbstract from './shared/agenda/InterventionAbstract';
import PosterSessionDetail from './shared/agenda/PosterSessionDetail';
import RegistrationFee from './pages/paxTickets/RegistrationFee';
import ProfileForm from './pages/paxTickets/ProfileForm';
import Faculty from './pages/Faculty';
import PaxCart from './pages/paxTickets/PaxCart';
import PaxTicketsBillingInformations from './pages/paxTickets/PaxTicketsBillingInformations';
import AdditionalServiceBillingInformations from './pages/additionalService/AdditionalServiceBillingInformations';
import VirtualBag from './pages/VirtualBag';
import GeneralInfo from './pages/GeneralInfo';
import { PaymentOptions, PaymentCart, PaymentSuccess } from '@aim/components';
import MyGrants from './pages/profile/MyGrants';
import MyTickets from './pages/profile/MyTickets';
import MySingleTicket from './pages/profile/MySingleTicket';

import MyInvoices from './pages/profile/MyInvoices';
import MyAllotment from './pages/profile/MyAllotment';
import MyAllotmentTicketWrapper from './pages/profile/myAllotment/MyAllotmentTicketWrapper';
import MyTicketAdditionalServices from './pages/profile/MyAdditionalServiceTicket';
import PublicLandingPage from './pages/PublicLandingPage';
import Allotment from './pages/Allotment';
import AllotmentHotelDetail from './pages/AllotmentHotelDetail';
import AllotmentBookingData from './pages/AllotmentBookingData';
// import AllotmentBookingInfo from './pages/allotment/BookingInfo';
import AllotmentBillingInformations from './pages/allotment/AllotmentBillingInformations';
import GrantDetails from './pages/GrantDetails';
import Grant from './pages/Grant';
import GrantSubscribeSuccess from './pages/GrantSubscribeSuccess';
import ControlCodePage from './pages/login/ControlCodePage';
import ProfileRegistrationDataEdit from './pages/profile/ProfileRegistrationDataEdit';
import OtherSponsorizationsSubcategory from './pages/myServices/OtherSponsorizationsSubcategory';
import OtherSponsorizationsItemsList from './pages/myServices/OtherSponsorizationsItemsList';
import EPoster from './pages/EPoster';
import SocialWall from './pages/SocialWall';
import SponsorBillingInformations from './pages/SponsorBillingInformations';
import SponsorPayment from './pages/SponsorPayment';
import PhysicalStandItemsCategory from './pages/myServices/PhysicalStandItemsCategory';
import SponsorCartBillingInformations from './pages/SponsorCartBillingInformations';
import CheckoutHandler from './pages/CheckoutHandler';
import ParticipationProfile from './pages/ParticipationProfile';
import EditPassword from './pages/profileEdit/EditPassword';
import LoungeWrapperComponent from './pages/lounge/LoungeWrapperComponent';
import CustomOrdersTable from './pages/CustomOrdersTable';
import Program from './pages/Program';

const Routes = () => {
  // useZendesk();
  const { eventId } = useParams();
  const location = useLocation();
  const history = useHistory();
  const intl = CustomIntl(useIntl());
  const [user, setUser] = useState();
  const [client, setClient] = useState();
  const [isFirstLoadCompleted, setIsFirstLoadCompleted] = useState(false);

  const preventRedirectRef = useRef(false);

  const setUserCallback = useCallback(
    (nextUser) => {
      if (user && !nextUser) {
        preventRedirectRef.current = true;
      } else {
        preventRedirectRef.current = false;
      }
      setUser(nextUser);
    },
    [user]
  );

  useEffect(() => {
    const subscription = appState.user.subscribe(setUserCallback);
    return () => {
      subscription.unsubscribe();
    };
  }, [setUserCallback]);

  useEffect(() => {
    if (!user?.userAndParticipation?.participation?.id) return;
    const subscriptionParticipationUpdate = onUpdateParticipationId(
      user?.userAndParticipation?.participation?.id,
      subscriptionCallback
    );

    setClient(
      new PubNub({
        publishKey: process.env.PUBNUB_PUBLISH_KEY,
        subscribeKey: process.env.PUBNUB_SUBSCRIBE_KEY,
        uuid: user?.userAndParticipation?.participation?.id,
      })
    );
    return () => {
      subscriptionParticipationUpdate.unsubscribe();
    };
  }, [user]);

  const GetUserData = async () => {
    if (!user) {
      setIsFirstLoadCompleted(await loadEventData({ eventId }));
    }
  };

  // Callbacks
  const subscriptionCallback = useCallback(() => {
    loadUserData({ eventId, history });
  }, [loadUserData]);

  const CheckUser = () => {
    if (!isFirstLoadCompleted) return false;

    let userControlCode = localStorage.getItem(`event-${eventId}-control-code`);
    try {
      const path =
        location.pathname.slice(-1) === '/'
          ? location.pathname.slice(0, -1)
          : location.pathname;
      if (path.endsWith('control-code')) {
        return true;
      } else if (
        !path.endsWith(`${eventId}/survey`) &&
        !path.endsWith(eventId) &&
        !path.endsWith('control-code') &&
        appState.eventInfo.getValue().isInsertControlCodeInANewUserEnabled &&
        appState.eventInfo.getValue().controlCodeCode &&
        (!userControlCode ||
          userControlCode !== appState.eventInfo.getValue().controlCodeCode)
      ) {
        history.push({
          pathname: `/events/${eventId}/control-code`,
          state: location,
        });
      } else {
        if (
          path.endsWith(eventId) &&
          !appState.eventConfiguration.getValue()
            ?.publicLandingPageActivationDate &&
          !appState.eventConfiguration.getValue()
            ?.publicLandingPageSurveyActivationDate
        ) {
          history.push(`/events/${eventId}/login`);
        } else if (!user?.awsUser) {
          if (
            !path.endsWith(`${eventId}/survey`) &&
            !path.endsWith(eventId) &&
            !path.endsWith('login') &&
            !path.endsWith('signup') &&
            !path.endsWith('verify') &&
            !path.endsWith('forgotpassword') &&
            !path.endsWith('password-expired') &&
            !path.endsWith('forgot-username') &&
            !path.includes(`/events/${eventId}/my-services/`) &&
            !path.endsWith('mailResponse') &&
            !path.includes('genericPayments') &&
            !path.includes('public-program')
          ) {
            history.push(`/events/${eventId}/login`, {
              prevPathname: !preventRedirectRef.current
                ? window.location.pathname
                : undefined,
            });
          }
        } else {
          if (user?.userAndParticipation?.participation?.isDeleted) {
            //to simplify our life when impersonate
            if (!user?.awsUser?.groups.includes('admin')) {
              aws.signOutFromWebsite();
            }
            history.push(`/events/${eventId}/login`);
          } else if (
            !path.includes(`/events/${eventId}/registration`) &&
            user?.awsUser &&
            !user?.userAndParticipation?.participation
          ) {
            history.push(`/events/${eventId}/registration`); // choice of reg as pax / agency / sponsor
          } else if (
            // pax backoffice exist but first access is not completed
            !path.includes(`/events/${eventId}/registration`) &&
            user.userAndParticipation.participation.cluster === 'pax' &&
            user?.awsUser &&
            user?.userAndParticipation?.participation
              ?.isFirstAccessCompleted === false
          ) {
            history.push(`/events/${eventId}/registration/pax`);
          } else if (
            path.endsWith('login') ||
            path.endsWith('signup') ||
            (path.includes('registration') &&
              user?.awsUser &&
              user?.userAndParticipation?.participation &&
              (user.userAndParticipation.participation.cluster !== 'pax' ||
                user?.userAndParticipation?.participation
                  ?.isFirstAccessCompleted !== false)) ||
            path.endsWith('verify')
          ) {
            if (
              history.location.state?.prevPathname !== window.location.pathname
            ) {
              history.push(
                history.location.state?.prevPathname ||
                  `/events/${eventId}/landing`
              );
            }
          }
        }
      }
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  GetUserData();

  if (!CheckUser()) return null;

  return (
    <Switch>
      <Route exact path="/events/:eventId">
        <PublicLandingPage />
      </Route>
      <Route exact path="/events/:eventId/login">
        <BasePage>
          <SignIn />
        </BasePage>
      </Route>
      <Route exact path="/events/:eventId/survey">
        <PublicSurveyPage />
      </Route>
      <Route exact path="/events/:eventId/checkout-handler/:paymentId">
        <CheckoutHandler />
      </Route>
      <Route exact path="/events/:eventId/control-code">
        <BasePage>
          <ControlCodePage />
        </BasePage>
      </Route>
      <Route exact path="/events/:eventId/signup">
        <BasePage>
          <SignUp />
        </BasePage>
      </Route>
      <Route exact path="/events/:eventId/verify">
        <BasePage>
          <IdentityVerification />
        </BasePage>
      </Route>
      <Route exact path="/events/:eventId/forgotpassword">
        <BasePage>
          <ForgotPassword />
        </BasePage>
      </Route>
      <Route exact path="/events/:eventId/forgot-username">
        <BasePage>
          <ForgotUsername />
        </BasePage>
      </Route>
      <Route exact path="/events/:eventId/password-expired">
        <BasePage>
          <ResetTemporaryPassword />
        </BasePage>
      </Route>
      <Route
        exact
        path="/events/:eventId/:breakoutRoomSlotId/:email/mailResponse"
      >
        <MailLandingPage />
        {/* <BasePage>

          </BasePage> */}
      </Route>
      <Route exact path="/events/:eventId/registration">
        <BasePage>
          <RegistrationType />
        </BasePage>
      </Route>
      <Route exact path="/events/:eventId/registration/pax">
        <BasePage>
          <PaxRegistrationForm />
        </BasePage>
      </Route>
      <Route exact path="/events/:eventId/registration/agency">
        <BasePage>
          <AgencyRegistrationForm />
        </BasePage>
      </Route>
      <Route exact path="/events/:eventId/registration/sponsor">
        <BasePage>
          <SponsorRegistrationForm />
        </BasePage>
      </Route>
      <Route exact path="/events/:eventId/registration/pax/:userId">
        <BasePage>
          <PaxRegistrationForm />
        </BasePage>
      </Route>
      <Route exact path="/events/:eventId/my-services/:participationServiceId">
        <ParticipationServicesPublicPage />
      </Route>
      <Route
        exact
        path="/events/:eventId/my-services/:participationServiceId/welcome"
      >
        <ParticipationWelcomePublicPage />
      </Route>
      <Route exact path="/events/:eventId/genericPayments/:genericPaymentId">
        <GenericPaymentsPublicPage />
      </Route>
      <Route exact path="/events/:eventId/public-program">
        <ProgramPublicPage />
      </Route>
      <Route
        exact
        path="/events/:eventId/genericPayments/:genericPaymentId/billing-info"
      >
        <GenericPaymentsBillingInformationsPublicPage />
      </Route>
      <Route
        exact
        path="/events/:eventId/genericPayments/:genericPaymentId/cart"
      >
        <GenericPaymentsCartPublicPage />
      </Route>
      <Route
        exact
        path="/events/:eventId/genericPayments/:genericPaymentId/checkout-response"
      >
        <GenericPaymentsCheckoutResponse />
      </Route>
      {client && (
        <PubNubProvider client={client}>
          <Layout>
            <>
              <LoungeWrapperComponent></LoungeWrapperComponent>
              <Switch>
                <Route exact path="/events/:eventId/landing">
                  <Landing />
                </Route>
                <Route exact path="/events/:eventId/grants/:grantType">
                  <Grants />
                </Route>
                <Route exact path="/events/:eventId/faculty">
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <Faculty />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route exact path="/events/:eventId/social-wall">
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <SocialWall />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route exact path="/events/:eventId/additional-services/:type">
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <AdditionalServices />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/additional-services/:type/:additionalServiceId/billing-info"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <AdditionalServiceBillingInformations />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path={[
                    '/events/:eventId/additional-services/:type/:additionalServiceId',
                    '/events/:eventId/additional-services/:type/:additionalServiceId/checkout-success',
                    '/events/:eventId/additional-services/:type/:additionalServiceId/checkout-error',
                  ]}
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <AdditionalService />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/:sponsorId/billing-informations"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <SponsorBillingInformations />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/:sponsorId/cart/billing-informations"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <SponsorCartBillingInformations />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route exact path="/events/:eventId/:sponsorId/price-ranges">
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <PriceRangesList />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route exact path="/events/:eventId/:sponsorId/my-services">
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <MyServices />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/:sponsorId/my-services/:service"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <WallDisabiguation />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/:sponsorId/my-services/physical-stand/:floorId"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <PhysicalStandFloorsList />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/:sponsorId/my-services/sponsor-packages/:sponsorPackageId"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <SponsorPackageDetails />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/:sponsorId/my-services/other-sponsorizations/:categoryId"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <OtherSponsorizationsSubcategory />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/:sponsorId/my-services/other-sponsorizations/:categoryId/:subcategoryId"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <OtherSponsorizationsItemsList />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route exact path="/events/:eventId/:sponsorId/cart">
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <SponsorCart />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route exact path="/events/:eventId/:sponsorId/payment-opt">
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <PaymentOptions intl={intl} history={history} />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route exact path="/events/:eventId/:sponsorId/payment">
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <SponsorPayment />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/:sponsorId/checkout-success"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <CheckoutSuccess />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route exact path="/events/:eventId/:sponsorId/checkout-error">
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <CheckoutError />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route exact path="/events/:eventId/:sponsorId/my-purchases">
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <MyPurchases />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/agency/:agencyId/my-purchases"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <MyAgencyPurchases />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route exact path="/events/:eventId/:sponsorId/invoices">
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <Invoices />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/sponsors/:sponsorId/slots/:breakoutRoomSlotId/"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <BreakoutRoomSlotDetail />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/:sponsorId/services-configuration"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <SponsorServiceConfigurationWall />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/:sponsorId/services-configuration/virtual-stands"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <VirtualStands />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/:sponsorId/services-configuration/physical-stands"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <PhysicalStandSpaces />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/:sponsorId/services-configuration/physical-stands/:spaceId"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <PhysicalStandCategoriesList />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/:sponsorId/services-configuration/physical-stands/:spaceId/category/:categoryId"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <PhysicalStandItemsCategory />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/:sponsorId/services-configuration/physical-stands/:spaceId/category/:categoryId/subcategory/:subCategoryId"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <PhysicalStandItemsList />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/:sponsorId/services-configuration/breakoutrooms"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <BreakoutRoomList />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/:sponsorId/services-configuration/breakoutrooms/:breakoutRoomId"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <BreakoutRoomReservations />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/:sponsorId/services-configuration/breakoutrooms/:breakoutRoomId/edit"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <BreakoutRoomConfiguration />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/:sponsorId/services-configuration/breakoutrooms/:breakoutRoomId/invite"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <BreakoutRoomInvite />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/:sponsorId/services-configuration/breakoutrooms/:breakoutRoomId/delete"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <BreakoutRoomSendDelete />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/:sponsorId/services-configuration/staff"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <SponsorStaffDetail />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/:sponsorId/services-configuration/list"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <SponsorListDetails />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/participation/:participationId/edit"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <ProfileRegistrationDataEdit />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/participation/:cluster/:clusterId/import"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <ImportFromFileFrontofficeWrapper />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/participation/:cluster/:clusterId/import/post-deadline"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <ImportFromFileFrontofficeWrapper isPostDeadline />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path={[
                    '/events/:eventId/participation/:cluster/:clusterId/new',
                    '/events/:eventId/participation/:cluster/:clusterId/:participationId',
                  ]}
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <ParticipationEditWrapperFrontoffice />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path={[
                    '/events/:eventId/participation/:cluster/:clusterId/:participationId/name-change',
                  ]}
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <ParticipationEditWrapperFrontoffice isNameChange />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path={[
                    '/events/:eventId/participation/:cluster/:clusterId/new/post-deadline',
                    '/events/:eventId/participation/:cluster/:clusterId/:participationId/post-deadline',
                  ]}
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <ParticipationEditWrapperFrontoffice isPostDeadline />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route exact path={'/events/:eventId/homepage'}>
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <Home />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route exact path={'/events/:eventId/sessions'}>
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <Sessions />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route exact path={'/events/:eventId/contents'}>
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <WebcastCardGrid />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route exact path={'/events/:eventId/speakers'}>
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <Speakers />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route exact path={'/events/:eventId/virtual-bag'}>
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <VirtualBag />
                  </ContentPageFrontendTemplate>
                </Route>
                {/* <Route exact path={'/events/:eventId/contacts'}>
              
                <ContentPageFrontendTemplate eventId={eventId}>
                  <Contacts />
                </ContentPageFrontendTemplate>
              
            </Route> */}
                <Route exact path={'/events/:eventId/general-info'}>
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <GeneralInfo />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route exact path={'/events/:eventId/e-poster'}>
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <EPoster />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route exact path={'/events/:eventId/sponsors'}>
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <Sponsors />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route exact path={'/events/:eventId/sponsors/:sponsorId'}>
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <SponsorDetail />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route exact path={'/events/:eventId/sponsors/:sponsorId/book'}>
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <SponsorBookingAppointment />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path={'/events/:eventId/sponsors/:sponsorId/book/confirm'}
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <SponsorBookingConfirmAppointment />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route exact path={'/events/:eventId/sponsors/:sponsorId/edit'}>
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <SponsorDetailEdit />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path={['/events/:eventId/:sponsorId/custom-orders']}
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <CustomOrdersTable />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path={[
                    '/events/:eventId/user/:participationId/custom-orders',
                  ]}
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <CustomOrdersTable />
                  </ContentPageFrontendTemplate>
                </Route>
                {/* <Route exact path={'/events/:eventId/lounge-area'}>
              
                <ContentPageFrontendTemplate eventId={eventId}>
                  <Chat />
                </ContentPageFrontendTemplate>
              
            </Route> */}
                {/* <Route exact path={'/events/:eventId/lounge-area'}>
                
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <Chat />
                  </ContentPageFrontendTemplate>
                
              </Route> */}
                {/* <Route exact path="/events/:eventId/lounge-area/:peerId">
                
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <Chat />
                  </ContentPageFrontendTemplate>
                
              </Route> */}
                <Route exact path="/events/:eventId/user/:participationId">
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <ParticipationProfile />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path={'/events/:eventId/user/:participationId/edit'}
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <ProfileEdit />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path={
                    '/events/:eventId/user/:participationId/edit/edit-password'
                  }
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <EditPassword />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/user/:participationId/surveys"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <Surveys />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/user/:participationId/services"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <Services />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/user/:participationId/certifications"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <Certifications />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/user/:participationId/invoices"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <MyInvoices />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/user/:participationId/grants/:grantId"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <GrantDetails />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/user/:participationId/additional-services"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <UserAdditionalServices />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/user/:participationId/my-tickets"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <MyTickets />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/user/:participationId/my-ticket/:productId"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <MySingleTicket />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/user/:participationId/my-allotment"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <MyAllotment />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/user/:participationId/my-grants/:grantType"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <MyGrants />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/user/:participationId/my-allotment/:productId"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <MyAllotmentTicketWrapper />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/user/:participationId/additional-services/:type/:productId"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <MyTicketAdditionalServices />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/user/:participationId/surveys/:surveyId"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <SurveyPage />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route exact path="/events/:eventId/webcast-list">
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <WebcastCardGrid />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route exact path="/events/:eventId/webcast-list/:webcastId">
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <WebcastCardDetail />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route exact path="/events/:eventId/agenda">
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <AgendaList />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route exact path="/events/:eventId/agenda/sessions/:sessionId">
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <AgendaEventDetail />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/agenda/sessions/:sessionId/abstract/:abstractId"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <InterventionAbstract />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/agenda/poster-session/:sessionId"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <PosterSessionDetail />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/agenda/poster-session/:sessionId/poster/:posterId"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <PosterDetail />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route exact path="/events/:eventId/landing-agenda">
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <Program />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route exact path="/events/:eventId/visa">
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <Visa />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route exact path="/events/:eventId/abstracts">
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <PaxAbstracts />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route exact path="/events/:eventId/allotment">
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <Allotment />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/allotment/:hotelEventVentureId"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <AllotmentHotelDetail />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/allotment/:hotelEventVentureId/booking"
                >
                  <ContentPageFrontendTemplate
                    eventId={eventId}
                    customBackgroundColour="#f8f6fa"
                  >
                    <AllotmentBookingData />
                  </ContentPageFrontendTemplate>
                </Route>
                {/* <Route
              exact
              path="/events/:eventId/allotment/:hotelEventVentureId/booking-info"
            >
              
                <ContentPageFrontendTemplate eventId={eventId}>
                  <AllotmentBookingInfo />
                </ContentPageFrontendTemplate>
              
            </Route> */}
                <Route
                  exact
                  path="/events/:eventId/allotment/:hotelEventVentureId/billing-info"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <AllotmentBillingInformations />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path={[
                    '/events/:eventId/allotment/:hotelEventVentureId/checkout-error',
                    '/events/:eventId/allotment/:hotelEventVentureId/checkout-success',
                  ]}
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <AllotmentBillingInformations />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route exact path="/events/:eventId/tickets/fee">
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <RegistrationFee />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path={[
                    '/events/:eventId/tickets/cart/:profileFeeBracketId/:feeType',
                    '/events/:eventId/tickets/cart/:profileFeeBracketId/:feeType/checkout-success',
                    '/events/:eventId/tickets/cart/:profileFeeBracketId/:feeType/checkout-error',
                  ]}
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <PaxCart />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path={[
                    '/events/:eventId/tickets/cart/:profileFeeBracketId/:feeType/payment-opt',
                    '/events/:eventId/additional-services/:type/:additionalServiceId/payment-opt',
                    '/events/:eventId/allotment/:hotelEventVentureId/payment-opt',
                  ]}
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    {/* <PaxPaymentOptions /> */}
                    <PaymentOptions intl={intl} history={history} />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path={[
                    '/events/:eventId/tickets/:profileFeeBracketId/payment-success',
                    '/events/:eventId/additional-services/:type/:additionalServiceId/payment-success',
                    '/events/:eventId/allotment/:hotelEventVentureId/payment-success',
                  ]}
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <PaymentSuccess
                      intl={intl}
                      history={history}
                      eventId={eventId}
                    />
                  </ContentPageFrontendTemplate>
                </Route>
                eventId={eventId}
                <Route
                  exact
                  path={[
                    '/events/:eventId/tickets/cart/:profileFeeBracketId/:feeType/payment',
                    '/events/:eventId/additional-services/:type/:additionalServiceId/payment',
                    '/events/:eventId/allotment/:hotelEventVentureId/payment',
                  ]}
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    {/* <PaxPayment /> */}
                    <PaymentCart
                      intl={intl}
                      history={history}
                      eventId={eventId}
                      isFrontOfficePurchase={true}
                    />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/tickets/billing-info/:profileFeeBracketId/:feeType"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <PaxTicketsBillingInformations />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route exact path="/events/:eventId/tickets/profile">
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <ProfileForm />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route exact path="/events/:eventId/presenter-abstracts">
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <PresenterAbstracts />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/abstracts-role-selection/:abstractServiceId"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <PaxAbstractsRoleSelection />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/abstracts-reviewers-categories"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <PaxAbstractsCategoriesSelection />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route exact path="/events/:eventId/abstracts/create">
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <PaxAbstractsEdit />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route exact path="/events/:eventId/abstracts/:abstractId/edit">
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <PaxAbstractsEdit />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route exact path="/events/:eventId/abstracts/:abstractId/view">
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <AbstractDetail />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/abstracts/:abstractId/presentation"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <PaxAbstractsPresentation />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route exact path="/events/:eventId/abstracts-reviewers/">
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <ReviewersAbstracts />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route exact path="/events/:eventId/abstracts-wall">
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <ReviewersAbstractsWall />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route exact path="/events/:eventId/reviewer-category">
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <ReviewerCategory />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/abstracts-detail/:abstractId/review"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <AbstractDetailReview from="list" />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/abstracts-detail/:abstractId/view"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <AbstractDetail from="list" />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route exact path="/events/:eventId/table-test">
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <TableTest />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path={[
                    '/events/:eventId/agency/:agencyId/groups/:groupId',
                    '/events/:eventId/agency/:agencyId/groups/:groupId/checkout-success',
                    '/events/:eventId/agency/:agencyId/groups/:groupId/checkout-error',
                    '/events/:eventId/agency/:agencyId/sponsor-lists/:sponsorListId',
                    '/events/:eventId/agency/:agencyId/sponsor-lists/:sponsorListId/checkout-success',
                    '/events/:eventId/agency/:agencyId/sponsor-lists/:sponsorListId/checkout-error',
                  ]}
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <AgencyGroupDetail />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route exact path="/events/:eventId/agency/:agencyId">
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <AgencyHomepage />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/agency/:agencyId/billing-informations"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <AgencyCartBillingInformations />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/agency/:agencyId/payment-opt"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <AgencyPaymentOptions />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route exact path="/events/:eventId/agency/:agencyId/payment">
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <AgencyPayment />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/agency/:agencyId/checkout-success"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <AgencyHomepage />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/agency/:agencyId/checkout-error"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <AgencyHomepage />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path={[
                    '/events/:eventId/agency/:agencyId/group-list/:groupId/standard/checkout-success',
                    '/events/:eventId/agency/:agencyId/group-list/:groupId/standard/checkout-error',
                  ]}
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <AgencyGroupDetail />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route exact path="/events/:eventId/grants/:grantId/subscribe">
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <Grant />
                  </ContentPageFrontendTemplate>
                </Route>
                <Route
                  exact
                  path="/events/:eventId/grants/:grantId/subscribe-success"
                >
                  <ContentPageFrontendTemplate eventId={eventId}>
                    <GrantSubscribeSuccess />
                  </ContentPageFrontendTemplate>
                </Route>
              </Switch>
            </>
          </Layout>
        </PubNubProvider>
      )}
    </Switch>
  );
};

export default Routes;
