import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { useFieldArray, useForm, Controller } from 'react-hook-form';
import { constants, utilities } from '@aim/common';
import AddIcon from '@material-ui/icons/Add';

import {
  AimList,
  AimIconButton,
  AimTypography,
  AimSelectForm,
  AimSelectMenuItem,
  AimNumericInput,
  // styled,
  theme,
  AimDialog,
} from '@aim/components';

import { getEventData } from './gqlHelper';
import { endOfDay, isEqual, isWithinInterval } from 'date-fns';

const formControlStyle = {
  width: 'calc(100% - 20px)',
};

const propsPriceNamesByParticipationMode = {
  [constants.EventTypes.PHYSICAL.id]: 'priceOnSite',
  [constants.EventTypes.VIRTUAL.id]: 'priceOnAir',
};

const getFeeDateRangesOfSelectedProfile = (
  feeDateRanges,
  profileBrackets,
  participationMode
) => {
  const feeDateRangesOfProfile = feeDateRanges.filter((dateRange) => {
    const test = profileBrackets.find((pfb) => {
      return (
        pfb?.[propsPriceNamesByParticipationMode?.[participationMode]] >= 0 &&
        pfb.feeBracket.feeDateRange.id === dateRange.id &&
        pfb.profileFeeConfiguration?.isActive
      );
    });
    return test;
  });

  return feeDateRangesOfProfile;
};

const setPriceValue = ({
  index,
  control,
  feeDateRanges,
  profileBrackets,
  participationType,
  feeDateRange,
}) => {
  if (!profileBrackets.length || !feeDateRange || !participationType) return;
  const feeDateRangeObj = feeDateRanges.find((fdr) => fdr.id === feeDateRange);
  const bracket = profileBrackets.find((profileFeeFracket) => {
    // find feebracket (con il daterange del partecipante)
    const isStartEqual = isEqual(
      new Date(feeDateRangeObj.start),
      new Date(profileFeeFracket.feeBracket.feeDateRange.start)
    );
    const isEndEqual = isEqual(
      new Date(feeDateRangeObj.end),
      new Date(profileFeeFracket.feeBracket.feeDateRange.end)
    );
    return isStartEqual && isEndEqual;
  });

  if (bracket) {
    if (participationType === constants.EventTypes.PHYSICAL.id) {
      control.setValue(`buyOperations[${index}].price`, bracket.priceOnSite);
    } else if (participationType === constants.EventTypes.VIRTUAL.id) {
      control.setValue(`buyOperations[${index}].price`, bracket.priceOnAir);
    }
  } else control.setValue(`buyOperations[${index}].price`, 0);
};

const handleQuantityChange = (index, control, value) => {
  if (value < 1) {
    return;
  }

  control.setValue(
    `buyOperations[${index}].quantity`,
    !isNaN(value) ? value : 1
  );
  // setTotal(parseInt(value, 10) * price);
};

const handleClick = (index, control, watchQuantity, value) => {
  const nextQuantity = watchQuantity + value;

  if (parseInt(nextQuantity) < 1) {
    return;
  }

  control.setValue(
    `buyOperations[${index}].quantity`,
    !isNaN(value) ? value : 1
  );
  // setTotal(parseInt(nextQuantity, 10) * price);
};

const Row = ({
  row,
  index,
  i18n,
  watch,
  control,
  intl,
  eventData,
  setAddButtonVisible,
}) => {
  const [participationModes, setParticipationModes] = useState([]);
  const [profileBrackets, setProfileBrackets] = useState([]);
  const [feeDateRanges, setFeeDateRanges] = useState([]);
  const watchProfile = watch(`buyOperations[${index}].profile`);
  const watchParticipationMode = watch(
    `buyOperations[${index}].participationMode`
  );
  const watchFeeDateRange = watch(`buyOperations[${index}].feeDateRange`);
  const watchQuantity = watch(`buyOperations[${index}].quantity`);

  useEffect(() => {
    if (!eventData || !watchProfile) return;
    const profileObj = eventData?.profiles.find((p) => p.id === watchProfile);
    const baseDate = new Date();
    const brackets = profileObj.profileFeeBrackets.items.filter(
      (profileFeeFracket) => {
        // find feebracket (in base allo scaglione)
        const isInDatesInterval = isWithinInterval(baseDate, {
          start: new Date(profileFeeFracket.feeBracket.feeBracketModel.start),
          end: endOfDay(
            new Date(profileFeeFracket.feeBracket.feeBracketModel.end)
          ),
        });
        return isInDatesInterval;
      }
    );
    setProfileBrackets(brackets);
    const nextParticipationModes = [];
    brackets.filter((b) => b.priceOnSite >= 0).length &&
      nextParticipationModes.push(constants.EventTypes.PHYSICAL);
    brackets.filter((b) => b.priceOnAir >= 0).length &&
      nextParticipationModes.push(constants.EventTypes.VIRTUAL);
    setParticipationModes(nextParticipationModes);
    if (nextParticipationModes.length === 1) {
      control.setValue(
        `buyOperations[${index}].participationMode`,
        nextParticipationModes[0].id
      );
    }
  }, [eventData, watchProfile]);

  useEffect(() => {
    if (!eventData || !profileBrackets.length || !watchParticipationMode)
      return;

    const feeDateRangesOfProfile = getFeeDateRangesOfSelectedProfile(
      eventData?.feeDateRanges,
      profileBrackets,
      watchParticipationMode
    );

    setFeeDateRanges(feeDateRangesOfProfile);
    setAddButtonVisible(
      eventData.profiles.length === 1 && feeDateRangesOfProfile.length === 1
        ? false
        : true
    );
    if (feeDateRangesOfProfile.length === 1) {
      control.setValue(
        `buyOperations[${index}].feeDateRange`,
        feeDateRangesOfProfile[0].id
      );
    }
  }, [eventData, profileBrackets, watchParticipationMode]);

  useEffect(() => {
    setPriceValue({
      index,
      control,
      feeDateRanges: eventData?.feeDateRanges,
      profileBrackets,
      participationType: watchParticipationMode,
      feeDateRange: watchFeeDateRange,
    });
  }, [profileBrackets, watchParticipationMode, watchFeeDateRange, eventData]);

  return (
    <Grid
      container
      alignItems="center"
      style={{
        margin: '0px 10px',
        padding: '0px',
        backgroundColor: theme.colors.greyScale.backgroundGrey,
        width: '100%',
      }}
      // key={row.formKey}
    >
      <Grid item xs={3}>
        <AimSelectForm
          selectVariant="white"
          control={control}
          name={`buyOperations[${index}].profile`}
          defaultValue={row.profile}
          selectPlaceholder={i18n.modal.grid.profile.placeholder}
          formControlStyle={formControlStyle}
        >
          {eventData?.profiles.map((item) => (
            <AimSelectMenuItem key={item.id} value={item.id}>
              {item.name}
            </AimSelectMenuItem>
          ))}
        </AimSelectForm>
      </Grid>
      <Grid item xs={3}>
        <AimSelectForm
          selectVariant="white"
          control={control}
          name={`buyOperations[${index}].participationMode`}
          defaultValue={row.participationMode}
          selectPlaceholder={i18n.modal.grid.participationType.placeholder}
          formControlStyle={formControlStyle}
          disabled={!participationModes.length}
        >
          {participationModes.map((eventType) => (
            <AimSelectMenuItem key={eventType.id} value={eventType.id}>
              {eventType.label(intl)}
            </AimSelectMenuItem>
          ))}
        </AimSelectForm>
      </Grid>
      <Grid item xs={3}>
        <AimSelectForm
          selectVariant="white"
          control={control}
          name={`buyOperations[${index}].feeDateRange`}
          defaultValue={row.feeDateRange}
          selectPlaceholder={i18n.modal.grid.feeDateRange.placeholder}
          formControlStyle={formControlStyle}
          disabled={!feeDateRanges}
        >
          {feeDateRanges?.map((item) => (
            <AimSelectMenuItem key={item.id} value={item.id}>
              {item.label}
            </AimSelectMenuItem>
          ))}
        </AimSelectForm>
      </Grid>
      <Grid item xs={1}>
        <Controller
          name={`buyOperations[${index}].price`}
          control={control}
          defaultValue={row.price}
          render={(props) => {
            return (
              <AimTypography
                variant={'text'}
                style={{
                  backgroundColor: 'white',
                  height: 46,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {utilities.formatNumber(utilities.safeNum(props.value))}
              </AimTypography>
            );
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <Controller
          name={`buyOperations[${index}].quantity`}
          control={control}
          render={(props) => (
            <AimNumericInput
              value={props.value || 0}
              editableInput
              onChange={(value) => handleQuantityChange(index, control, value)}
              onClickPlus={() => handleClick(index, control, 1)}
              onClickMinus={() => handleClick(index, control, -1)}
              disabledMinus={!watchQuantity || watchQuantity <= 1}
              disabledPlus={!watchParticipationMode}
              style={{ minHeight: 'auto' }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
  // </Container>;
};

const SponsorStaffDialog = ({
  isDialogOpen,
  // style,
  eventId,
  i18n,
  intl,
  onDisagree,
  onAgree,
  sponsorStaffId,
}) => {
  const { control, getValues, watch, formState, reset } = useForm({
    buyOperations: [],
    shouldUnregister: false,
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'buyOperations',
    keyName: 'formKey',
  });

  const [eventData, setEventData] = useState();
  const [total, setTotal] = useState();
  const [canBuy, setCanBuy] = useState();
  const [viewParticipationCol, setViewParticipationCol] = useState();

  const [addButtonVisible, setAddButtonVisible] = useState(true);
  console.log('canBuy', canBuy);

  const addRow = (eventData) => {
    append({
      profile:
        eventData.profiles.length === 1 ? eventData.profiles[0].id : undefined,
      quantity: 0,
      price: 0,
    });
  };

  const getData = async () => {
    const eventData = await getEventData(eventId, sponsorStaffId);

    setViewParticipationCol(
      eventData.eventType === constants.EventTypes.HYBRID.id
    );

    // add row
    addRow(eventData);

    setEventData(eventData);
  };

  useEffect(() => {
    if (eventId && isDialogOpen) {
      getData();
    }
    // setTotal(parseInt(quantity, 10) * price);
  }, [eventId, isDialogOpen]);

  const calcTotal = () => {
    const { buyOperations } = getValues();
    let canBuy = true;
    if (buyOperations) {
      const total = buyOperations
        .reduce((res, curr) => {
          if (
            curr.quantity &&
            curr.profile &&
            curr.feeDateRange &&
            curr.participationMode
          )
            return res + curr.price * curr.quantity;
          else {
            canBuy = false;
            return res;
          }
        }, 0)
        .toFixed(2);
      setCanBuy(canBuy);
      setTotal(total);
    } else {
      setTotal(0);
      setCanBuy(false);
    }
  };

  useEffect(() => {
    calcTotal();
  }, [formState]);

  const onPurchaseClick = () => {
    const { buyOperations } = getValues();

    onAgree(buyOperations);
  };

  const HeaderRow = (
    <Grid
      container
      alignItems="center"
      style={{
        margin: '0px 10px 10px 10px',
        padding: '0px',
        width: '100%',
      }}
    >
      <Grid item xs={3}>
        <AimTypography variant={'h6'} style={{ marginLeft: '30px' }}>
          {i18n.modal.grid.profile.label}
        </AimTypography>
      </Grid>
      <Grid item xs={3}>
        <AimTypography variant={'h6'} style={{ marginLeft: '30px' }}>
          {i18n.modal.grid.participationType.label}
        </AimTypography>
      </Grid>
      <Grid item xs={3}>
        <AimTypography variant={'h6'} style={{ marginLeft: '30px' }}>
          {i18n.modal.grid.feeDateRange.label}
        </AimTypography>
      </Grid>
      <Grid item xs={1}>
        <AimTypography variant={'h6'}>{i18n.modal.grid.price}</AimTypography>
      </Grid>
      <Grid item xs={2}>
        <AimTypography variant={'h6'}>
          {i18n.modal.grid.quantity.label}
        </AimTypography>
      </Grid>
    </Grid>
  );

  return (
    <AimDialog
      disableEnforceFocus
      fullWidth
      maxWidth={viewParticipationCol ? 'lg' : 'md'}
      open={isDialogOpen}
      title={i18n.modal.title}
      onClose={() => {
        reset({ buyOperations: [] });
        onDisagree();
      }}
      disagreeText={i18n.modal.buttons.cancel}
      agreeText={i18n.modal.buttons.buy}
      onDisagree={onDisagree}
      onAgree={() => onPurchaseClick()}
      // onAgreeDisabled={
      //   !profile || !feeDateRange || !feeDateRanges || !participationMode
      // }
      onAgreeDisabled={!canBuy}
    >
      <AimTypography
        variant="h4Regular"
        boxStyle={{ marginBottom: 20, marginTop: -5 }}
      >
        {i18n.modal.subTitle}
      </AimTypography>
      <AimList
        rows={fields}
        rowKey="id"
        onDeleted={(e, row, idx) => remove(idx)}
        isDeleteSmall
        deleteIconVariant="yellow"
        itemStyle={{ margin: 0, padding: '0px 0px 3px 0px' }}
        childrenProps={{
          control,
          i18n,
          intl,
          watch,
          eventData,
          setAddButtonVisible,
        }}
        marginHeader={false}
        HeaderRow={HeaderRow}
      >
        <Row />
      </AimList>
      <Grid
        container
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          paddingRight: '8px',
          marginBottom: '10px',
        }}
      >
        <AimTypography variant={'h6'}>
          {i18n.modal.grid.total.label}
        </AimTypography>
        <AimTypography variant={'text'}>{total}</AimTypography>
      </Grid>
      <Grid
        container
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          paddingRight: '8px',
          marginBottom: '10px',
        }}
      ></Grid>
      {addButtonVisible && (
        <div>
          <AimIconButton variant="yellowFill" onClick={() => addRow(eventData)}>
            <AddIcon />
          </AimIconButton>
        </div>
      )}
    </AimDialog>
  );
};

export default SponsorStaffDialog;
