import React, { useState, useEffect, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import { useFieldArray, useForm, Controller } from 'react-hook-form';

import {
  AimList,
  AimTypography,
  // AimNumericInput,
  // styled,
  theme,
  AimDialog,
} from '@aim/components';

import { getAdditionalServices } from './gqlHelper';

const formControlStyle = {
  width: 'calc(100% - 20px)',
};

const handleQuantityChange = (index, control, value) => {
  if (value < 1) {
    return;
  }

  control.setValue(`bookings[${index}].quantity`, !isNaN(value) ? value : 1);
  // setTotal(parseInt(value, 10) * price);
};

const handleClick = (index, control, watchQuantity, value) => {
  const nextQuantity = watchQuantity + value;

  if (parseInt(nextQuantity) < 1) {
    return;
  }

  control.setValue(`bookings[${index}].quantity`, !isNaN(value) ? value : 1);
  // setTotal(parseInt(nextQuantity, 10) * price);
};

const Row = ({ row, index, i18n, watch, control }) => {
  const watchId = watch(`additionalServices[${index}].id`);
  const watchQuantity = watch(`additionalServices[${index}].quantity`);

  return (
    <Grid
      container
      alignItems="center"
      // style={{
      //   margin: '0px 10px',
      //   padding: '0px',
      //   width: '100%',
      // }}
      // key={row.formKey}
    >
      <Grid item xs={9}>
        <AimTypography variant="text">{row.title}</AimTypography>
      </Grid>
      {/* <Grid item xs={3}>
        <Controller
          name={`bookings[${index}].quantity`}
          control={control}
          render={(props) => (
            <AimNumericInput
              value={props.value || 0}
              editableInput
              onChange={(value) => handleQuantityChange(index, control, value)}
              onClickPlus={() => handleClick(index, control, 1)}
              onClickMinus={() => handleClick(index, control, -1)}
              disabledMinus={!watchQuantity || watchQuantity <= 1}
              // disabledPlus={!watchParticipationMode}
              style={{ minHeight: 'auto' }}
            />
          )}
        />
      </Grid> */}
    </Grid>
  );
};

export const TicketAdditionalServicesDialog = ({
  isDialogOpen,
  // style,
  eventId,
  i18n,
  onDisagree,
  onAgree,
  selectedTicket,
  history,
}) => {
  const { control, getValues, watch, formState, reset } = useForm({
    bookings: [],
    shouldUnregister: false,
  });
  const { fields } = useFieldArray({
    control,
    name: 'additionalServices',
    keyName: 'formKey',
  });

  const [checkedItems, setCheckedItems] = useState([]);
  const [additionalServices, setAdditionalServices] = useState([]);
  // const additionalServicesRef = useRef();

  useEffect(() => {
    if (eventId && selectedTicket) {
      const fetchAdditionalServices = async () => {
        const nextAdditionalServices = await getAdditionalServices(eventId);
        const filteredAdditionalServices = nextAdditionalServices?.items.filter(
          (x) => x.participationType === selectedTicket.type
        );
        if (filteredAdditionalServices.length) {
          setAdditionalServices(filteredAdditionalServices);
          reset({ additionalServices: filteredAdditionalServices });
        } else {
          history.push(
            `/events/${eventId}/tickets/billing-info/${selectedTicket.id}/${selectedTicket.type}`
          );
        }
      };
      fetchAdditionalServices(eventId);
    }
  }, [eventId, isDialogOpen]);

  const onConfirmClick = () => {
    onAgree(checkedItems);
  };

  const HeaderRow = (
    <Grid
      container
      // alignItems="center"
      // style={{
      //   margin: '0px 10px 10px 10px',
      //   padding: '0px',
      //   width: '100%',
      // }}
    >
      <Grid item xs={9}>
        <AimTypography boxStyle={{ margin: '0 0 0 8px' }} variant={`textBold`}>
          {i18n.additionalServicesConfirmationDialog.header.title}
        </AimTypography>
      </Grid>
      {/* <Grid item xs={3}>
        <AimTypography variant={'h6'} style={{ marginLeft: '30px' }}>
          {i18n.additionalServicesConfirmationDialog.header.number}
        </AimTypography>
      </Grid> */}
    </Grid>
  );

  if (additionalServices.length) {
    return (
      <AimDialog
        disableEnforceFocus
        fullWidth
        // maxWidth={viewParticipationCol ? 'lg' : 'md'}
        open={isDialogOpen}
        title={i18n.additionalServicesConfirmationDialog.title}
        onClose={() => {
          reset({ additionalServices: [] });
          onDisagree();
        }}
        disagreeText={i18n.additionalServicesConfirmationDialog.buttons.cancel}
        agreeText={i18n.additionalServicesConfirmationDialog.buttons.confirm}
        onDisagree={onDisagree}
        onAgree={() => onConfirmClick()}
      >
        <AimList
          headerStyle={{
            padding: 20,
            backgroundColor: theme.colors.greyScale.backgroundGrey,
            paddingLeft: 5,
          }}
          itemStyle={{
            backgroundColor: 'white',
            margin: 0,
            padding: 10,
            paddingLeft: 5,
          }}
          onChecked={setCheckedItems}
          checkedItems={checkedItems}
          checkable={true}
          deletable={false}
          rows={fields}
          rowKey="id"
          // onDeleted={(e, row, idx) => remove(idx)}
          // isDeleteSmall
          // deleteIconVariant="yellow"
          // itemStyle={{ margin: 0, padding: '0px 0px 3px 0px' }}
          marginHeader={false}
          HeaderRow={HeaderRow}
        >
          <Row {...{ control, rows: fields, i18n, watch }} />
        </AimList>
      </AimDialog>
    );
  } else return null;
};
